import greenlet from './greenlet';
import usePromise from 'react-promise-suspense';
import handlebars from './handlebars';
import jexl from './jexl';

/**
 * Thread to execute JavaScript expressions.
 */
const AsyncFunction = Object.getPrototypeOf(async function () { }).constructor;
const jsExprWorkerAsync = greenlet(new AsyncFunction('func', 'args', 'params', `
    return new Function(...args, func)(...params);
`));

// caches for various compiled expression languages
const handlebarsCache = {};
const jexlCache = {};

const getHandlebarsExpr = (expression) => handlebarsCache[expression] ?? (handlebarsCache[expression] = handlebars.compile(expression));
const getJexlExpr = (expression) => jexlCache[expression] ?? (jexlCache[expression] = jexl.compile(expression));

/**
 * Evaluates the format expression.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const evalExpressionAsync = async (type, expression, data) => {
    try {
        if (type === 'string' && expression) {
            return expression;
        } else if (type === 'json' && expression) {
            return JSON.parse(expression);
        } else if (type === 'handlebars' && expression) {
            return getHandlebarsExpr(expression)(data);
        } else if (type === 'javascript' && expression) {
            return (await jsExprWorkerAsync(`return (value => ((${expression})))(value);`, ['value'], [data]))?.toString();
        } else if (type === 'jexl' && expression) {
            return await getJexlExpr(expression).eval(data);
        } else {
            return null;
        }
    } catch (e) {
        if (e) {
            console.error(e);
        }
        return null;
    }
}

/**
 * Monitors the type, expression and data for changes and returns the resulting formatted string.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const useExpression = (type, expression, data) => {
    return usePromise(evalExpressionAsync, [type, expression, data]);
};
