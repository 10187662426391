import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from "./app";
import './assets/stylesheets/index.scss';
import * as serviceWorker from './serviceWorker';

/**
 * Loads the application configuration.
 */
const getConfigAsync = async () => {
    return await (await fetch('config.json', { headers: { Accept: 'application/json' } })).json()
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Suspense fallback={null}>
                <App getConfigAsync={getConfigAsync} onLoadComplete={window.onAppLoadComplete} />
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("app")
);

serviceWorker.unregister();
