import React from "react";

export function TagImage({
    id,
    content,
    corner1X,
    corner1Y,
    corner2X,
    corner2Y,
    offset,
    scale
}) {
    const rect = {
        x1: Math.min(corner1X + offset.x, corner2X + offset.x),
        y1: Math.min(corner1Y + offset.y, corner2Y + offset.y),
        x2: Math.max(corner1X + offset.x, corner2X + offset.x),
        y2: Math.max(corner1Y + offset.y, corner2Y + offset.y),
    }

    const style = {
        left: `${100 * (rect.x1 / scale.width)}%`,
        bottom: `${100 * (rect.y1 / scale.height)}%`,
        width: `${100 * ((rect.x2 - rect.x1) / scale.width)}%`,
        height: `${100 * ((rect.y2 - rect.y1) / scale.height)}%`,
    };

    return (
        <img
            data-image-id={id}
            className={`tag-image`}
            style={style}
            src={content} />
    );
}
