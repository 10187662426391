import React from 'react';
import { Stack, Typography, Button, } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth } from '../contexts/auth';
import { useUser } from '../contexts/user';
import { ReactComponent as ForbiddenImage } from '../assets/images/401.svg';

export const ForbiddenPage = () => {
    const auth = useAuth();
    const user = useUser();

    const tryLogin = async () => {
        await auth.login();
    };

    return (
        <Stack alignItems="center" justifyContent="center" spacing={4} sx={{ mt: 4 }}>
            {
                auth && !user ? (
                    <>
                        <Typography variant="h3" component="h3">
                            Authentication Required
                        </Typography>
                        <Typography variant="h5" component="h5">
                            Welcome to Accu-traq. Please log in to continue.
                        </Typography>
                        <ForbiddenImage height="200" alt="Forbidden" />
                        <Button size="large" variant="outlined" startIcon={<LoginIcon />} onClick={() => tryLogin()}>
                            Log In
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="h3" component="h3">
                            Authorization Required
                        </Typography>
                        <Typography variant="h5" component="h5">
                            Access to this page is denied.
                        </Typography>
                        <ForbiddenImage height="200" alt="Forbidden" />
                    </>
                )
            }
        </Stack>
    );
};
