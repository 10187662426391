import React, { useMemo } from "react";
import { TagDot } from "./tag-dot";

export function TagDots({ region, tags, scale, size = 1.2 }) {
    const offset = useMemo(() => {
        if (region && region.zones) {
            return {
                x: -(Math.min(Math.min(...region.zones.map(i => i.corner1X)), Math.min(...region.zones.map(i => i.corner2X)))),
                y: -(Math.min(Math.min(...region.zones.map(i => i.corner1Y)), Math.min(...region.zones.map(i => i.corner2Y)))),
            };
        } else {
            return null;
        }
    }, [region.id]);

    if (region && region.zones && tags) {
        return <>
            {
                tags.filter(tag => tag).map(tag =>
                    <TagDot
                        key={tag.id}
                        region={region}
                        tag={tag}
                        offset={offset}
                        scale={scale}
                        size={size}
                    />
                )
            }
        </>;
    } else {
        return null;
    }
}
