import React, { useState, useEffect, useMemo } from 'react';
import { useAccount } from '../contexts/account';
import { useApi, useApiAsync } from '../contexts/api';
import { useApiFetch } from '../helpers/api-fetch';
import { useInterval } from 'beautiful-react-hooks';
import { useAuth } from '../contexts/auth';
import { useUser } from '../contexts/user';
import { useLayout } from '../components/layout';
import { Loading } from '../components/loading';
import { ForbiddenPage } from "./forbidden";
import { NotFoundPage } from './notfound';
import { Box, Grid, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, Collapse, Modal, TextField, Button, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { defaultInventoryFields, evalFormatExpressionAsync, fieldExprExec, getFieldColorsAsync } from '../helpers/table-field';

/**
 * Checks whether the user has configuration access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountRoleAsync = async (auth, user, account, api, type) => {
    if (auth) {
        const currentAccount = account?.accounts.filter((acc) => acc.id === account.selectedId)[0];
        if (currentAccount && currentAccount.id) {
            const { data } = await api.all('userAccountRoles').get({ filter: `and(equals(account.id,'${currentAccount.id}'),equals(user.id,'${user.id}'))` });

            if (Array.isArray(type)) {
                return data.some(i => type.includes(i.type));
            } else {
                return data.some(i => i.type === type);
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};

/**
 * Checks whether the user has admin access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountAdminAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, 'admin');

/**
 * Page that displays an inventory.
 * @param {any} param0
 */
export const InventoryPage = ({ inventoryId }) => {
    const account = useAccount();
    const apiFetch = useApiFetch();

    const [inventory, setInventory] = useState();
    const GetInventoryAsync = async () => {
        if(inventoryId === null){
            return [];
        }
    
        const response = await apiFetch(`v1/inventories/${inventoryId}`, {
            method: 'GET',
            headers: {Accept : '*/*'}
        });
    
        if(response.ok === true){
            const json = await response.json();
            const inventory = await json.data;
    
            setInventory(inventory);
        }
    }
    useEffect(() => {
        setInventory(GetInventoryAsync());
    }, [inventoryId]);

    const { data, isPending, reload } = useApiAsync((api, accountId) => accountId ? api.one('inventory', inventoryId).all('product').get({ include: 'assets' }) : null, [inventoryId]);

    // load the inventory and various dependencies
    useInterval(reload, 3000000);

    if (data && data.account && data.account.id !== account?.selectedId) {
        return <ForbiddenPage />;
    } else if (data && data.data) {
        return <InventoryHost inventory={inventory} productData={data} isPending={isPending} />;
    } else if (isPending === false) {
        return <NotFoundPage />;
    } else {
        return <Loading />;
    }
};

/**
 * View that displays an inventory.
 * @param {any} param0
 */
const InventoryHost = ({ inventory, productData, isPending }) => {
    const [products, setProducts] = useState(null);

    // receive updates to inventory
    useEffect(() => {
        if (productData && productData.data) {
            setProducts(productData.data);
        }
    }, [productData, isPending]);

    // update the page when the inventory is changed
    const layout = useLayout();
    useEffect(() => { layout.setTitle(inventory?.attributes?.name); return () => layout.setTitle(null); }, [inventory.id]);

    if (inventory && products) {
        return <InventoryView inventory={inventory} products={products} setProducts={setProducts} />;
    } else {
        return <Loading />;
    }
}

/**
 * Page that displays an inventory.
 * @param {any} param0
 */
export const InventoryView = ({ inventory, products, setProducts }) => {
    const auth = useAuth();
    const account = useAccount();
    const user = useUser();
    const api = useApi();   
    
    const [productModalOpen, setProductModalOpen] = React.useState(false);

    const handleOpenProductModal = () => setProductModalOpen(true);
    const handleCloseProductModal = () => setProductModalOpen(false);

    const [hasAdmin, setHasAdmin] = useState(false);

    useEffect(async () => {
        setHasAdmin(await hasAccountAdminAsync(auth, user, account, api));
    }, [auth, user, account, api]);

    // Gets the product data
    const [productData, setProductData] = useState(null);

    useEffect(() => {
        if (inventory && products) {
            const products_ = Object.values(products);

            let temp = products_;

            setProductData(temp);
        }
        else {
            setProductData([]);
        }
    }, [inventory.id, products]);

    // show loading screen until tags are available
    if (productData === null) {
        return <Loading />;
    }

    if (inventory === null) {
        return <></>;
    }

    return (
        <Box p={0}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={productModalOpen}
                onClose={handleCloseProductModal} >
                <Box>
                    <ProductModal closeModal={handleCloseProductModal} allProducts={productData} setProductData={setProducts} inventoryId={inventory.id} />
                </Box>
            </Modal>
            <Grid container spacing={2} alignItems="stretch" sx={{ height: 1 }}>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={12} md={12}>
                    {
                        hasAdmin ? (
                            <Button variant="contained" onClick={handleOpenProductModal} sx={{ width:'100%', my:'5px' }}>Add new product</Button>
                        ) : null
                    }
                    <TableCard inventory={inventory} products={productData} setProductData={setProducts} hasAdmin={hasAdmin} />
                </Grid>
            </Grid>
        </Box>
    );
}

/**
 * Renders the card into which the product table goes.
 * @param {any} param0
 */
const TableCard = ({ inventory, products, setProductData, hasAdmin }) => {
    return (
        <Card sx={{ height: 1 }}>
            <CardContent sx={{ height: 1, minHeight: 400 }}>
                <ProductTable inventory={inventory} products={products} setProductData={setProductData} hasAdmin={hasAdmin} />
            </CardContent>
        </Card>
    );
};

const ProductTable = ({ inventory, products, setProductData, hasAdmin }) => {
    const [data, setData] = useState([]);
    const [sort, setSort] = useState({ by: null, reverse: false });

    /**
     * Changes the currently sorted field by index. If called on the field that is already sorted, reverses the sort.
     * @param {any} by
            */
    const changeSort = by => {
        setSort({ by: by, reverse: sort.by === by ? !sort.reverse : false });
    };

    // compares the two values
    const compareFunc = (a, b) => {
        if (a === b) {
            return 0;
        } else if (a === null) {
            return -1;
        } else if (b === null) {
            return 1;
        } else if (a > b) {
            return -1;
        } else if (b > a) {
            return 1;
        } else {
            return 0;
        }
    };

    // transforms the current product set into the final sorted data set.
    useEffect(() => {
        if (products) {
            let temp = [...products];

            temp = temp.sort(compareFunc);

            if (sort.reverse) {
                temp = temp.reverse();
            }

            setData(temp);
        } else {
            setData([]);
        }
    }, [inventory, products, sort.by, sort.reverse]);

    return (
        <Box sx={{ height: 1 }}>
            <TableContainer sx={{ height: 1, maxHeight: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell
                                title={'Name'}
                                sortDirection={sort.reverse ? 'desc' : 'asc'}
                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <TableSortLabel
                                    active={sort.by === 0}
                                    direction={sort.reverse ? 'desc' : 'asc'}
                                    onClick={ev => changeSort(0)}>
                                    {'Name'}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                title={'Total Quantity'}
                                sortDirection={sort.reverse ? 'desc' : 'asc'}
                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <TableSortLabel
                                    active={sort.by === 1}
                                    direction={sort.reverse ? 'desc' : 'asc'}
                                    onClick={ev => changeSort(1)}>
                                    {'Total Quantity'}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                title={'Reorder Point'}
                                sortDirection={sort.reverse ? 'desc' : 'asc'}
                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <TableSortLabel
                                    active={sort.by === 2}
                                    direction={sort.reverse ? 'desc' : 'asc'}
                                    onClick={ev => changeSort(2)}>
                                    {'Reorder Point'}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((product) => <Row product={product} key={product.id} AllProducts={products} SetProducts={setProductData} inventory={inventory} hasAdmin={hasAdmin} /> )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

/**
 * Renders the appropriate content for a custom expression field and asset.
 * @param {any} param0
            */
const AssetTableFieldDisplay = ({ field, asset }) => {
    const [value, setValue] = useState(null);
    useEffect(() => (async (field, asset) => setValue(await evalFormatExpressionAsync(field.formatExpressionType, field.formatExpression, asset)))(field, asset), [field, asset]);
    return <span>{value}</span>;
};

/**
 * Maps the InventoryField.Type values to their render and value implementations. render() is used to output the HTML for the value of the field, while value() returns a sortable version.
 * */
const fieldTypeMap = {
    expression: {
        render: (asset, field) => (<div><AssetTableFieldDisplay field={field} asset={asset} /></div>),
        value: (asset, field) => fieldExprExec(asset, field),
    },
    key: {
        render: (asset, field) => (<div>{asset.key ? <div className={`zone-header ${asset.key}`}>{asset.key}</div> : "N/A"}</div>),
        value: (asset, field) => asset.key ? asset.key : null,
    },
    name: {
        render: (asset, field) => (<div>{asset?.name}</div>),
        value: (asset, field) => asset?.name,
    },
    quantity: {
        render: (asset, field) => (<div>{asset?.quantity}</div>),
        value: (asset, field) => asset?.quantity,
    }
}

const Row = ({product, AllProducts, SetProducts, inventory, hasAdmin}) => {
    const { selectedId: accountId } = useAccount() ?? {};    
    const apiFetch = useApiFetch();
    const [assetsOpen, setAssetsOpen] = React.useState(false);
    const [productModalOpen, setProductModalOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const handleOpenProductModal = () => setProductModalOpen(true, product);
    const handleCloseProductModal = () => setProductModalOpen(false);

    const handleOpenDeleteDialog = () => setDeleteDialogOpen(true, product);
    const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

    const productQuantity = product.assets?.length > 0 ? product.assets?.filter(a => a.active).map(a => a.quantity).reduce((a,b)=>a+b, 0) : 0;
    const rowColor = productQuantity <= product.reorderPoint ? '#FFC7CE' : '';
    const textColor = productQuantity <= product.reorderPoint ? '#9C0006' : '';

    // set of fields to be used for the assets of this product
    const fields = useMemo(() => inventory && inventory.attributes && inventory.attributes.fields && inventory.attributes.fields.length > 0 ? inventory.attributes.fields : defaultInventoryFields, [inventory]);

    const handleDelete = async() => {
        await apiFetch(`v1/products?id=${product.id}&accountId=${accountId}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'}
        });

        //update the state
        SetProducts(AllProducts.filter(p => p.id !== product.id));
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={productModalOpen}
                onClose={handleCloseProductModal} >
                <Box>
                    <ProductModal product={product} closeModal={handleCloseProductModal} allProducts={AllProducts} setProductData={SetProducts} inventoryId={inventory.id} />
                </Box>
            </Modal>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                {"Product Delete"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you certain you want to delete this Product?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>Nevermind</Button>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>DELETE</Button>
                </DialogActions>
            </Dialog>
            <TableRow sx={{ backgroundColor: rowColor }}>
                <TableCell>
                    <DisplayAssetIcon setOpen={setAssetsOpen} open={assetsOpen} display={product.assets?.filter(a => a.active).length > 0} />
                </TableCell>
                <TableCell sx={{ color: textColor }}>{product.name}</TableCell>
                <TableCell sx={{ color: textColor }}>{productQuantity}</TableCell>
                <TableCell sx={{ color: textColor }}>{product.reorderPoint}</TableCell>
                <TableCell>
                    {
                        hasAdmin ? (
                            <IconButton aria-label="edit" onClick={handleOpenProductModal} >
                                <EditIcon />
                            </IconButton>
                        ) : null
                    }
                </TableCell>
                <TableCell>
                    {
                        hasAdmin ? (
                            <IconButton aria-label="delete" onClick={handleOpenDeleteDialog}>
                                <DeleteIcon />
                            </IconButton>  
                        ) : null
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={assetsOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        {/* <Typography variant="h6" gutterBottom component="div"> */}
                            <h2>Assets</h2>
                        {/* </Typography> */}
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        fields.map((field, index) => (
                                            <TableCell
                                                key={index}
                                                title={field.name}
                                                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {field.name}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {product.assets?.filter(a => a.active).map((assetRow) => (
                                    <TableRow key={assetRow.key}>
                                        {
                                            fields.map((field, index) => (
                                                <AssetTableCell key={index} field={field} asset={assetRow}>
                                                    {fieldTypeMap[field.type].render(assetRow, field)}
                                                </AssetTableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const AssetTableCell = ({ field, asset, children }) => {
    const [color, setColor] = useState({});
    useEffect(() => (async (field, asset) => setColor(await getFieldColorsAsync(field, asset)))(field, asset), [field, asset]);

    return (
        <TableCell style={{ color: color.secondary, backgroundColor: color.primary }}>
            {children}
        </TableCell>
    );
};

const ProductModal = (data) => {
    const { selectedId: accountId } = useAccount() ?? {};    
    const apiFetch = useApiFetch();

    const product = data.product;
    const [productId, setProductId] = useState(product?.id);
    const [productName, setProductName] = useState(product?.name);
    const [productReorderPoint, setProductReorderPoint] = useState(product?.reorderPoint);

    const handleProductIdChange = event => {
        setProductId(event.target.value);
    }

    const handleProductNameChange = event => {
        setProductName(event.target.value);
    }

    const handleProductReorderPointChange = event => {
        setProductReorderPoint(parseFloat(event.target.value));
    }

    const productModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleSave = async () => {
        // Create the JSON body
        const product = {
            id: productId,
            name: productName.trim(),
            reorderPoint: productReorderPoint,
            assets: []
        };

        // If product ID is null, post new product, else update existing product
        if(productId !== undefined){
            const response = await apiFetch(`v1/products?accountId=${accountId}&inventoryId=${data.inventoryId}`, {
                method: 'PUT',
                body: JSON.stringify(product),
                headers: {'Content-Type': 'application/json'}
            });

            const updatedProduct = await response.json();

            //update the state
            data.setProductData(data.allProducts.map(p =>
                p.id === productId ? { ...p, name: updatedProduct.name, reorderPoint: Number(updatedProduct.reorderPoint), assets: updatedProduct.account.assets } : p
            ));
        }
        else{
            // Create the new product
            const response = await apiFetch(`v1/products?accountId=${accountId}&inventoryId=${data.inventoryId}`, {
                method: 'POST',
                body: JSON.stringify(product),
                headers: {'Content-Type': 'application/json'}
            });

            // Get the newly created Id
            const newProduct = await response.json();
            product.id = newProduct.id;
            product.assets = newProduct.account.assets;

            //update the state
            data.setProductData([...data.allProducts, product]);
        }
        
        data.closeModal();
    }

    const validName = () => {
        return productName?.trim().length > 0 && !data.allProducts.filter((x) => x.id !== productId).some((x) => x.name === productName.trim());
    }

    const validQuantity = () => {
        return productReorderPoint >= 0 && Number.isInteger(productReorderPoint);
    }

    const [invalidInput, setInvalidInput] = useState(true);
    useEffect(() => {
        setInvalidInput(!validName() || !validQuantity());
    }, [productName, productReorderPoint]);

    return (
        <Box sx={productModalStyle}>
            <Box>
                <ModalHeader product={product} />
            </Box>
            <Stack spacing={2}>
                <TextField
                    id="product-name"
                    label="Name"
                    defaultValue={product ? product.name : null}
                    helperText={!validName() ? "Name must be longer than 0 characters, and cannot already exist" : "The name of the product. Extra white spaces will be trimmed off"}
                    onChange={handleProductNameChange}
                    error={!validName()} />
                <TextField
                    id="product-reorder-point"
                    label="Reorder Point"
                    defaultValue={product ? product.reorderPoint : null}
                    helperText={!validQuantity() ? "Reorder point must be a positive integer" : "The reorder point for the product"}
                    onChange={handleProductReorderPointChange}
                    type="number"
                    error={!validQuantity()}
                    InputLabelProps={{
                        shrink: true
                    }} />
            </Stack>
            <Box component="span"
                m={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Button variant="contained" color="success" disabled={invalidInput} startIcon={<SaveIcon />} onClick={handleSave}>Save</Button>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={data.closeModal}>Cancel</Button>
            </Box>
        </Box>
    );
}

const ModalHeader = (data) => {
    const product = data.product
    if(product){
        return (
            <Box sx={{ textAlign:'center', color: 'black' }}>
                <h1>Edit Product</h1>
            </Box>
        );
    }
    
    return (
        <Box sx={{ textAlign:'center', color: 'black' }}>
            <h1>New Product</h1>
        </Box>
    );
}


const DisplayAssetIcon = (data) => {
    if(data.display){
        return (
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => data.setOpen(!data.open)} >
                {data.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        );
    }
    
    return null;
}