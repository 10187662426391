import React, { useRef, useState } from 'react';
import { useErrorHandler } from "react-error-boundary";
import { Typography, Box, AppBar, Toolbar, Grid, Button, IconButton, Tooltip, Avatar, Menu, MenuItem, Divider, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import { useAuth } from '../contexts/auth';
import { useUser } from '../contexts/user';
import { useApiAsync } from "../contexts/api";
import { useAccount } from "../contexts/account";

export const Header = ({ title, onNavOpen }) => {
    const auth = useAuth();
    const user = useUser();

    return (
        <AppBar variant="header" position="sticky" elevation={5}>
            <Toolbar>
                <Grid container spacing={1} alignItems="center" p={2}>
                    {user || auth === null ? (
                        <Grid sx={{ display: { xs: 'block' } }} item>
                            <IconButton color="inherit" aria-label="open drawer" onClick={onNavOpen} edge="start" size="large">
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    ) : null}
                    <Grid item xs alignItems="left">
                        <Typography color="inherit" variant="h1" component="h1">
                            <strong>Accu-traq</strong>
                            {
                                title ? (
                                    <> / {title}</>
                                ) : null
                            }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Help">
                            <IconButton color="inherit">
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {user || auth === null ? (
                        <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton color="inherit">
                                    <NotificationsIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ) : null}
                    <UserMenuGridItem auth={auth} user={user} />
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

const UserName = ({ auth, user }) => {
    return <span>{auth?.oidc?.oidcUser?.profile?.name}</span>;
};

const UserAvatar = ({ auth, user }) => {
    return auth?.oidc?.oidcUser?.profile ? (<Avatar src={auth?.oidc?.oidcUser?.profile?.picture} alt={auth?.oidc?.oidcUser?.profile?.name} />) : null;
};

const UserMenuGridItem = ({ auth, user }) => {
    const handleError = useErrorHandler();

    const anchorRef = useRef();
    const [open, setOpen] = useState(false);
    const onClick = () => setOpen(true);
    const onClose = () => setOpen(false);

    const tryLogin = async () => {
        try {
            await auth.login();
        } catch (e) {
            handleError(e);
        }
    };

    if (auth && user) {
        return (
            <Grid item>
                <Tooltip title={auth?.oidc?.oidcUser?.profile?.name}>
                    <IconButton ref={anchorRef} color="inherit" sx={{ p: 0.5 }} onClick={onClick} aria-controls={open ? 'account-menu' : null} aria-haspopup="true" aria-expanded={open ? 'true' : null}>
                        <UserAvatar auth={auth} user={user} />
                    </IconButton>
                </Tooltip>
                <UserMenu anchor={anchorRef.current} open={open} onClose={onClose} auth={auth} user={user} />
            </Grid>
        );
    } else if (auth) {
        return (
            <Grid item>
                <IconButton color="inherit" sx={{ p: 0.5 }} onClick={() => tryLogin()}>
                    <LoginIcon />
                </IconButton>
            </Grid>
        );
    } else {
        return <></>
    }
};

const UserMenu = ({ anchor, open, onClose, auth, user }) => {
    const account = useAccount();
    const handleError = useErrorHandler();
    const { data, isPending } = useApiAsync(f => auth ? f.all('accounts').get() : f.all('accounts').get({ filter: `equals(id,'00000000-0000-0000-0000-000000000001')` }));

    const tryLogout = async () => {
        try {
            await auth.logout();
        } catch (e) {
            handleError(e);
        }
    }

    return (
        <Menu anchorEl={anchor} open={open} onClose={onClose} onClick={onClose} PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
        }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem>
                <ListItemIcon>
                    <UserAvatar auth={auth} user={user} />
                </ListItemIcon>
                <ListItemText>
                    <UserName auth={auth} user={user} />
                </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    Settings
                </ListItemText>
            </MenuItem>
            {
                data && data.data ? [
                    <Divider key={0} />,
                    data.data.map(i => (
                        <MenuItem key={i.id} selected={account && account.selectedId === i.id} onClick={() => account.setSelectedId(i.id)}>
                            <ListItemIcon>
                                <BusinessIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>
                                {i.name}
                            </ListItemText>
                        </MenuItem>
                    )),
                    < Divider key={1} />
                ] : null
            }
            <MenuItem onClick={() => tryLogout()}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    Log Out
                </ListItemText>
            </MenuItem>
        </Menu>
    );
};
