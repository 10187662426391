import React, { createContext, useContext, useState } from "react";

export const SelectionsContext = createContext(null);
SelectionsContext.displayName = "SelectionsContext";

export function useSelectionsContext() {
    return useContext(SelectionsContext);
}

export const SelectionsContextProvider = ({ children }) => {
    const [selection, setSelection] = useState();

    return (
        <SelectionsContext.Provider value={{ selection: selection, setSelection: setSelection, }}>
            {children}
        </SelectionsContext.Provider>
    );
};
