import React, { useState, createContext, useEffect } from 'react';
import { useContext } from 'react';
import { Snackbar, Alert, Stack } from '@mui/material';
import { useAccount } from '../contexts/account';
import { CancellationToken, CancellationError } from '../helpers/cancellation';

const TaskContext = createContext();
export const useTasks = () => useContext(TaskContext);

/**
 * Represents a single outstanding item awaiting completion.
 * @param {any} param0
 */
const TaskItem = ({ item, onClose }) => {
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={true} autoHideDuration={6000} onClose={onClose}>
                <Alert severity={item.severity} sx={{ width: '100%' }} onClose={onClose}>
                    {item.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

/**
 * Maintains a set of outstanding tasks indexed by key. Consumers can register tasks to be completed by key, or
 * retrieve existing tasks by key. Tasks are expected to return an object with a message and severity property.
 * The user is notified upon completion. The task structure returned by get or add contains a promise that allows
 * the user to wait for completion, and a cancellationToken that allows the user to check whether the task was
 * canceled.
 */
export const TaskContextProvider = ({ children }) => {
    const account = useAccount();

    const [tasks, setTasks] = useState({});
    const [items, setItems] = useState([]);

    /**
     * Gets an existing task by key.
     * @param {any} key
     */
    const get = (key) => {
        return tasks[key] ?? null;
    };

    /**
     * Adds a new task to be executed if not already added.
     * @param {any} key
     * @param {any} func
     */
    const getOrAdd = (key, func) => {
        return get(key) ?? add(key, func);
    }

    /**
     * Gets an existing task by key, or schedules a new task.
     * @param {any} key
     * @param {any} func
     */
    const add = (key, func) => {
        if (tasks[key]) {
            throw new Error(`Task with key '${key}' already exists.`);
        }

        // define disconnected promise to signal completion
        let resolveFunc = null;
        let rejectFunc = null;
        const promise = new Promise((resolve, reject) => { resolveFunc = resolve; rejectFunc = reject; });

        // create a new task record, with the promise and a cancellation token        
        let cancellationToken = new CancellationToken();
        setTasks({ ...tasks, [key]: { promise, cancellationToken } });

        // begin execution of the task
        (async () => {
            try {
                // excute function, resolve successful result
                const result = await func(cancellationToken);
                resolveFunc(result);

                // add result to displayed items
                setItems([...items, { key: key, message: result.message, severity: result.severity }]);
            } catch (err) {
                // reject with error
                rejectFunc(err);

                // display catch-all, but only if not aborted
                if ((err instanceof CancellationError) === false && cancellationToken.isCancelled === false) {
                    setItems([...items, { key: key, message: err.message ?? 'An unknown error occurred. Please contact your administrator.', severity: 'error' }]);
                }
            } finally {
                setTasks({ ...tasks, [key]: undefined });
            }
        })();

        // return task structure to caller
        return tasks[key];
    };

    /**
     * Removes an item that was closed.
     * @param {any} key
     */
    const removeItem = (index) => {
        setItems(items.filter((_, i) => i != index));
    };

    /**
     * Cancels all outstanding tasks.
     */
    const cancelAll = () => {
        for (var task of Object.values(tasks)) {
            if (task && task.cancellationToken) {
                task.cancellationToken.cancel();
            }
        }
    }

    useEffect(() => {
        cancelAll();
    }, [account?.selectedId])

    return (
        <TaskContext.Provider value={{ add, get, getOrAdd }}>
            {children}
            {items.map((item, index) => (<TaskItem key={index} item={item} onClose={() => removeItem(index)} />))}
        </TaskContext.Provider>
    );
};
