import React, { createContext, useContext } from 'react';
import { AuthenticationProvider, useReactOidc } from '@axa-fr/react-oidc-context';
import { useConfig } from './config';
import urlJoin from 'proper-url-join';

const AuthContext = createContext(null);
AuthContext.displayName = 'AuthContext';

export const useAuth = () => useContext(AuthContext);

const AuthContextReadyScope = ({ oidc, children }) => {
    return (
        <>
            <AuthContext.Provider value={{
                ready: true,
                login: oidc.login,
                logout: oidc.logout,
                oidc: oidc
            }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

const AuthContextScope = ({ children }) => {
    const oidc = useReactOidc();

    if (oidc) {
        return (
            <AuthContextReadyScope oidc={oidc}>
                {children}
            </AuthContextReadyScope>
        );
    } else {
        return (
            <AuthContext.Provider value={{
                ready: false,
                login: oidc.login,
                logout: oidc.logout,
                oidc: oidc
            }}>
                <div>
                    <div>Authentication not ready</div>
                </div>
            </AuthContext.Provider>
        );
    }
};

export const AuthContextProvider = ({ children }) => {
    const config = useConfig();

    if (config.Authentication.Authority) {
        return (
            <AuthenticationProvider configuration={{
                authority: config.Authentication.Authority,
                client_id: config.Authentication.ClientId,
                redirect_uri: urlJoin(config.BaseUri, 'authentication/callback'),
                silent_redirect_uri: urlJoin(config.BaseUri, 'authentication/silent_callback'),
                response_type: 'code',
                scope: ['openid', 'profile', 'email'].concat(config.Api.Authentication.DefaultScopes ?? []).join(' '),
                extraQueryParams: config.Api.Authentication.ExtraQueryParams,
                automaticSilentRenew: true,
                loadUserInfo: false
            }}>
                <AuthContextScope>
                    {children}
                </AuthContextScope>
            </AuthenticationProvider>
        );
    } else {
        return (
            <AuthContext.Provider value={null}>
                {children}
            </AuthContext.Provider>
        );
    }
};
