import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useApiAsync } from './api';
import { useAuth } from './auth';
import { useUser } from './user';

const AccountContext = createContext(null);
AccountContext.displayName = 'AccountContext';

export const useAccount = () => useContext(AccountContext);

const AccountContextProviderReady = ({ children }) => {
    const navigate = useNavigate();
    const auth = useAuth();
    const [selectedId, setSelectedId] = useState();
    const { data, isPending } = useApiAsync(api => auth ? api.all('accounts').get() : api.all('accounts').get({ filter: `equals(id,'00000000-0000-0000-0000-000000000001')` }));

    useEffect(() => {
        if (!isPending) {
            if (!selectedId) {
                if (data && data.data) {
                    if (data.data.length > 0) {
                        setSelectedId(data.data[0].id);
                    }
                } else {
                    setSelectedId('00000000-0000-0000-0000-000000000001');
                }
            }
        }
    }, [isPending, selectedId, data]);

    const setSelectedIdFunc = (id) => {
        setSelectedId(id);
        navigate('/');
    }

    const value = data ? {
        accounts: data.data,
        selectedId: selectedId,
        setSelectedId: id => setSelectedIdFunc(id),
    } : null;

    return (
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>
    );
};

export const AccountContextProvider = ({ children }) => {
    const auth = useAuth();
    const user = useUser();

    if ((auth && auth.ready && user) || !auth) {
        return <AccountContextProviderReady>{children}</AccountContextProviderReady>;
    } else {
        return <AccountContext.Provider value={null}>{children}</AccountContext.Provider>;
    }
};
