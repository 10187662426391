import React, { useState, useEffect, useRef } from 'react'
import { Vector3, Box3, Color, MathUtils } from 'three';
import usePromise from 'react-promise-suspense';
import { Canvas, useFrame } from '@react-three/fiber'
import { Box, Edges, OrbitControls, Html, useGLTF, useFBX } from '@react-three/drei'
import { useAuth } from '../contexts/auth';
import { useSelectionsContext } from '../contexts/selection';
import { RegionTagPopup } from './region-tag-popup';
import './region-view-3d.scss'
import { Suspense } from 'react';
import { defaultColors, evalColorExpressionAsync } from '../helpers/colors';

/**
 * Converts a HTML color representation to a ThreeJS value.
 */
const toThreeJSColor = (value) => {
    return value ? new Color(value) : null;
};

// map colors into ThreeJS colors
const defaultColorsThreeJS = Object.keys(defaultColors).reduce((p, c) => ({ ...p, [c]: toThreeJSColor(defaultColors[c]) }), {});

/**
 * Loads a GLTF scene object from a URL.
 * @param {any} param0
 */
const RegionSceneGLTFObject = ({ object, url }) => {
    const { scene } = useGLTF(url.toString());

    scene.traverse(o => {
        if (o.isMesh) {
            o.castShadow = true;
            o.receiveShadow = true;
        } else if (o.isLight) {
            o.shadow.camera.updateProjectionMatrix();
        }
    });

    return (
        <primitive
            object={scene}
            scale={[object.scale[0], object.scale[2], object.scale[1]]}
            position={[object.position[0], object.position[2], -object.position[1]]}
            rotation={[object.rotation[0], object.rotation[2], object.rotation[1]]}
            castShadow
            receiveShadow />
    );
}

/**
 * Loads a FBX scene object from a URL.
 * @param {any} param0
 */
const RegionSceneFBXObject = ({ object, url }) => {
    const scene = useFBX(url.toString());
    return <primitive object={scene} dispose={null} scale={[object.scale[0], object.scale[2], object.scale[1]]} position={[object.position[0], object.position[2], -object.position[1]]} rotation={[object.rotation[0], object.rotation[2], object.rotation[1]]} castShadow receiveShadow />;
}

/**
 * Loads a scene object from a URL, depending on the content type.
 * @param {any} param0
 */
const RegionSceneObjectLoader = ({ object, url, contentType }) => {
    switch (contentType) {
        case 'model/gltf+json':
        case 'model/gltf-binary':
            return <RegionSceneGLTFObject object={object} url={url} />;
        case 'application/octet-stream+fbx':
            return <RegionSceneFBXObject object={object} url={url} />;
        default:
            throw new Error('unknown media type');
    }
}

/**
 * Renders a RegionSceneObject.
 * @param {any} param0
 */
const RegionSceneObject = ({ object, accessToken }) => {

    const content = usePromise(async (content, accessToken) => {
        const contentUrl = new URL(content);
        if (accessToken) {
            contentUrl.searchParams.set('access_token', accessToken);
        }

        try {
            const response = await fetch(contentUrl.toString(), {
                method: 'HEAD'
            });

            const contentType = response.headers.get('content-type');
            if (contentType) {
                return { url: contentUrl, type: contentType };
            }
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }, [object.content, accessToken]);

    if (content) {
        return (
            <Suspense fallback={null}>
                <RegionSceneObjectLoader object={object} url={content.url} contentType={content.type} />
            </Suspense>
        );
    }

    return <></>;
};

/**
 * Renders a zone in the scene.
 * @param {any} param0
 */
const ZoneBox = ({ zone, selected }) => {
    const faceRef = useRef();

    // calculate dimensions of zone
    const box = new Box3();
    box.setFromPoints([new Vector3(zone.corner1X, zone.corner1Z, -zone.corner1Y), new Vector3(zone.corner2X, zone.corner2Z, -zone.corner2Y)]);
    const pos = box.getCenter(new Vector3());
    const szz = box.getSize(new Vector3());

    // opacity lerps based on selection
    const faceOpacity = selected ? .5 : 0;
    useFrame(state => {
        if (faceRef.current) {
            faceRef.current.material.opacity = MathUtils.lerp(faceRef.current.material.opacity, faceOpacity, 0.1);
        }
    });

    return (
        <Box ref={faceRef} args={szz.toArray()} position={pos.toArray()} userData={{ select: { priority: -1, type: 'zone', item: zone.id } }}>
            <meshPhongMaterial attach="material" color={defaultColorsThreeJS[zone.color]} transparent={true} />
            <Edges>
                <meshBasicMaterial color={defaultColorsThreeJS[zone.color]} transparent={true} depthTest={false} />
            </Edges>
        </Box>
    );
}

const SELECTED_PRIMARY_COLOR = new Color('red');

/**
 * Gets the color for the given zone.
 */
const getZoneColor = (zone) => {
    return zone && zone.color ? defaultColors[zone.color] : null;
};

/**
 * Watches the monitor and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const evalTagColorAsync = async (region, tag) => {
    return {
        primary: region.tagPrimaryColorExpressionType ? await evalColorExpressionAsync(region.tagPrimaryColorExpressionType, region.tagPrimaryColorExpression, tag) : getZoneColor(tag.zone),
        secondary: region.tagSecondaryColorExpressionType ? await evalColorExpressionAsync(region.tagSecondaryColorExpressionType, region.tagSecondaryColorExpression, tag) : null,
    };
}

/**
 * Watches the tag and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const useTagColor = (region, tag) => {
    const [state, setState] = useState({ primary: null, secondary: null });

    useEffect(() => {
        (async (region, tag) => {
            try {
                setState(await evalTagColorAsync(region, tag));
            } catch (e) {
                console.log(e);
            }
        })(region, tag);
    }, [region, tag])

    return state;
}

/**
 * Renders a tag in the scene.
 * @param {any} param0
 */
const TagObject = ({ region, tag, selected }) => {
    const ref = useRef();
    const [once, setOnce] = useState(false);
    const [over, setOver] = useState(false)
    const vec = new Vector3(tag.x, tag.z, -tag.y);

    useFrame(state => {
        if (once !== true) {
            ref.current.position.copy(vec);
            setOnce(true)
        } else {
            ref.current.position.lerp(vec, .025);
        }
    });

    const color = useTagColor(region, tag);
    const primaryColor = selected ? SELECTED_PRIMARY_COLOR : toThreeJSColor(color.primary);

    useFrame(state => {
        ref.current.scale.x = ref.current.scale.y = ref.current.scale.z = MathUtils.lerp(ref.current.scale.z, over ? 1.5 : 1, 0.1);
        if (primaryColor) {
            ref.current.material.color.lerp(primaryColor, 0.1);
        }
    });

    return (
        <Box
            ref={ref}
            args={[.1, .1, .1]}
            onPointerOver={ev => { ev.stopPropagation(); setOver(true); }}
            onPointerOut={ev => { ev.stopPropagation(); setOver(false); }}
            castShadow
            receiveShadow
            userData={{ select: { priority: 0, type: 'tag', item: tag.id } }}>
            <meshStandardMaterial attach="material" />
            {selected ? (
                <Html distanceFactor={10}>
                    <RegionTagPopup region={region} tag={tag} color={color} />
                </Html>
            ) : null}
        </Box>
    );
}

/**
 * Once the camera and controls are available, the camera is moved into position to fit all of the region's zones on
 * the screen.
 * @param {any} param0
 */
const InitialViewPortFocus = ({ region, fitRatio = 1.2 }) => {
    const [done, setDone] = useState(false);

    useFrame(state => {
        if (done !== true) {
            const { camera, controls } = state;
            if (camera && controls) {
                // expand a box to encompass both zones
                const box = new Box3();
                for (const zone of region.zones) {
                    box.expandByPoint(new Vector3(zone.corner1X, zone.corner1Z, -zone.corner1Y));
                    box.expandByPoint(new Vector3(zone.corner2X, zone.corner2Z, -zone.corner2Y));
                }

                const sz = box.getSize(new Vector3());
                const cx = box.getCenter(new Vector3());

                const fitHeightDistance = Math.max(sz.x, sz.y, sz.z) / (2 * Math.atan((Math.PI * camera.fov) / 360));
                const fitWidthDistance = fitHeightDistance / camera.aspect;
                const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance);

                controls.maxDistance = distance * 10;
                controls.target.copy(cx);

                camera.near = distance / 100;
                camera.far = distance * 100;
                camera.updateProjectionMatrix();

                // find direction of camera
                const direction = controls.target.clone().sub(camera.position).normalize().multiplyScalar(distance);
                camera.position.copy(controls.target).sub(direction);

                controls.update();
                setDone(true);
            }
        }
    });

    return <group />;
};

/**
 * Three-dimensional view of a region.
 * @param {any} param0
 */
export const RegionView3D = ({ region, scene, tags, style }) => {
    const auth = useAuth();
    const { selection, setSelection } = useSelectionsContext();

    let accessToken = null;
    if (auth) {
        if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
            accessToken = auth.oidc.oidcUser.access_token;
        }
    }

    const getTagSelected = (id) => selection?.type === 'tag' && selection?.item === id;
    const getZoneSelected = (id) => selection?.type === 'zone' && selection?.item === id;

    const onCanvasCreated = state => {
        state.gl.physicallyCorrectLights = true;
    };

    const onClick = ev => {

        // two objects with index and select
        const sortFunc = (a, b) => {
            if (a.select.priority < b.select.priority) {
                return -1
            } else if (a.select.priority > b.select.priority) {
                return 1;
            } else {
                if (a.index < b.index) {
                    return 1;
                } else if (a.index > b.index) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };

        // find first intersected object with select userdata, sorted by priority
        const target = ev.intersections
            .map((i, j) => ({ index: j, select: i.object?.userData?.select }))
            .filter(i => i.select)
            .sort(sortFunc)
            .map(i => i.select)
            .find(i => true);

        // apply selection
        if (target) {
            if (selection && selection.type === target.type && selection.item === target.item) {
                setSelection(null);
            } else {
                setSelection({ type: target.type, item: target.item });
            }
        }

        ev.stopPropagation();
    };

    return (
        <div style={style}>
            <div className="RegionView3D-container">
                <Canvas camera={{ position: [0, 50, 0] }} shadowMap style={{ height: '100%', width: '100%' }} onCreated={onCanvasCreated}>
                    <group onClick={onClick}>
                        {region.zones.map(zone => <ZoneBox key={zone.id} zone={zone} selected={getZoneSelected(zone.id)} />)}
                        {scene.objects.map(object => <Suspense key={object.id} fallback={<group />}><RegionSceneObject object={object} accessToken={accessToken} /></Suspense>)}
                        {tags.map(tag => <TagObject key={tag.id} region={region} tag={tag} selected={getTagSelected(tag.id)} />)}
                    </group>
                    <OrbitControls makeDefault />
                    <InitialViewPortFocus region={region} />
                </Canvas>
            </div>
        </div>
    );
};
