import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from 'beautiful-react-hooks';
import { Box, Card, CardContent, CardHeader, SpeedDialIcon, SpeedDial, SpeedDialAction, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import urlJoin from 'proper-url-join';
import Chart from 'react-apexcharts';
import usePromise from 'react-promise-suspense';
import * as moment from 'moment';
import { HubConnection, HubConnectionState, HubConnectionBuilder } from "@microsoft/signalr";
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useApi, useApiAsync } from '../contexts/api';
import { useAuth } from '../contexts/auth';
import { useConfig } from '../contexts/config';
import { useSelectionsContext } from "../contexts/selection";
import { RegionView2D } from '../components/region-view-2d';
import { RegionView3D } from '../components/region-view-3d';
import { Loading } from '../components/loading';
import { defaultColors, evalColorExpressionAsync } from '../helpers/colors';
import { defaultRegionFields, evalFormatExpressionAsync, fieldExprExec, getFieldColorsAsync } from '../helpers/table-field';
import { ForbiddenPage } from "./forbidden";
import { useAccount } from '../contexts/account';
import { useLayout } from '../components/layout';
import { NotFoundPage } from './notfound';
import { useCallback } from 'react';
import { useUser } from '../contexts/user';


import Button from "@mui/material/Button";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

/**
 * Renders a functional child that is passed an action method that causes a file browser.
 * @param {any} param0
 */
const FileSelectContext = ({ children, onSelect }) => {
    const input = useRef(null);

    const start = async () => {
        if (input) {
            input.current.click();
        }
    };

    const onChange = async (ev) => {
        ev.preventDefault();

        if (input) {
            await onSelect(ev.target.files);
        }
    }

    return (
        <>
            <input ref={input} type="file" style={{ display: 'none' }} onChange={onChange} />
            {children(start)}
        </>
    );
};

/**
 * Gets the region with the specified ID, with the extended zone color information.
 * @param {any} id
 */
const getRegionAsync = async (api, id) => {
    if (id) {
        const c = Object.keys(defaultColors);
        const d = await api.one('region', id).get({ include: 'account,zones,zones.groups,images,scenes,scenes.objects,meters,meters.monitors' });

        return d.data ? {
            ...d.data,
            zones: d.data.zones.map((zone, i) => ({
                ...zone,
                color: c[i % c.length],
            }))
        } : null;
    } else {
        return null;
    }
}

/**
 * Checks whether the user has configuration access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountRoleAsync = async (auth, user, account, api, type) => {
    if (auth) {
        if (account && account.id && user && user.id) {
            const { data } = await api.all('userAccountRoles').get({ filter: `and(equals(account.id,'${account.id}'),equals(user.id,'${user.id}'))` });

            if (Array.isArray(type)) {
                return data.some(i => type.includes(i.type));
            } else {
                return data.some(i => i.type === type);
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};

/**
 * Checks whether the user has admin access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountAdminAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, 'admin');

/**
 * Page that displays a region.
 * @param {any} param0
 */
export const RegionPage = ({ regionId }) => {
    const { selectedId: accountId } = useAccount() ?? {};

    // load the region and various dependencies
    const { data, isPending, reload } = useApiAsync((api, regionId) => getRegionAsync(api, regionId), [regionId]);
    useInterval(reload, 60000);

    if (data && data.account && data.account.id !== accountId) {
        return <ForbiddenPage />;
    } else if (data && data.id === regionId) {
        return <RegionHost region={data} />;
    } else if (isPending === false) {
        return <NotFoundPage />;
    } else {
        return <Loading />;
    }
}

const useHub = (onConnectAsync) => {
    const config = useConfig();
    const auth = useAuth();
    const connection = useRef(null);
    const [retry, setRetry] = useState(0);


    // connect to SignalR hub
    useEffect(() => {
        const accessTokenFactory = () => {
            if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
                return auth.oidc.oidcUser.access_token;
            }
        };

        // create a new SignalR connection to the hub
        connection.current = new HubConnectionBuilder()
            .withUrl(urlJoin(config.Hub.BaseUri, 'regions'), { accessTokenFactory: auth ? accessTokenFactory : null })
            .withAutomaticReconnect()
            .build();
    }, [config?.Hub?.BaseUri, auth])

    // manage the hub's connection state
    useEffect(() => {
        // async runs in background
        if (connection.current && connection.current.state === HubConnectionState.Disconnected) {
            (async () => {
                try {
                    // start if in initial disconnected state
                    await connection.current.start();
                    if (onConnectAsync) {
                        connection.current.onreconnected(async () => await onConnectAsync(connection.current));
                        await onConnectAsync(connection.current);
                    }
                } catch (err) {
                    console.log(err);
                    setTimeout(() => setRetry(r => r + 1), 5000);
                }
            })();
        }

        // attempt to disconnect on dismount
        return () => {
            if (connection.current && connection.current.state === HubConnectionState.Connected) {
                (async () => {
                    try {
                        await connection.current.stop();
                    } catch (err) {
                        console.log(err);
                    }
                })();
            }
        };
    }, [connection.current, retry]);

    // return connection to user
    return connection.current;
}

const useHubMethod = (connection, methodName, callback) => {
    const cb = useCallback(callback);
    const [subscribed, setSubscribed] = useState(false);

    useEffect(() => {

        // once we have a connection, we can subscribe to messages
        if (connection && subscribed === false) {
            connection.on(methodName, cb);
            setSubscribed(true);
        }

        // unsubscribe on dismount
        return () => {
            if (connection && subscribed) {
                connection.off(methodName, cb);
            }
        }
    }, [connection, connection?.state, methodName, cb])
};

/**
 * View that displays a region.
 * @param {any} param0
 */
const RegionHost = ({ region }) => {
    const connection = useHub(async connection => await connection.invoke('Join', region.id));
    const [tags, setTags] = useState(null);
    const [assets, setAssets] = useState(null);
    const [owners, setOwners] = useState(null);
    const [meters, setMeters] = useState(null);

    // update the page when the region is changed
    const layout = useLayout();
    useEffect(() => { layout.setTitle(region.name); return () => layout.setTitle(null); }, [region.id]);
    useEffect(() => { setTags(null); setAssets(null); setOwners(null); setMeters(null); }, [region.id]);

    useHubMethod(connection, 'TagState', messages => {
        setTags(tags_ => {
            const tags__ = { ...tags_ };

            for (var i of messages) {
                tags__[i.id] = {
                    ...tags__[i.id],
                    id: i.id,
                    key: i.key,
                    regionId: i.regionId,
                    zoneId: i.zoneId,
                    x: i.x,
                    y: i.y,
                    z: i.z,
                    locateTime: i.locateTime ? new moment.utc(i.locateTime).toDate() : null,
                };
            }

            return tags__;
        });
    });

    // receives TagLocationState messages
    useHubMethod(connection, 'TagLocationState', messages => {
        setTags(tags_ => {
            const tags__ = { ...tags_ };

            for (var i of messages) {
                tags__[i.id] = {
                    ...tags__[i.id],
                    id: i.id,
                    key: i.key,
                    regionId: i.regionId,
                    zoneId: i.zoneId,
                    x: i.x,
                    y: i.y,
                    z: i.z,
                    locateTime: i.locateTime ? moment.utc(i.locateTime).toDate() : null,
                };
            }

            return tags__;
        });
    });

    // receives AssetState messages
    useHubMethod(connection, 'AssetState', messages => {
        setAssets(assets_ => {
            const assets__ = { ...assets_ };

            for (var i of messages) {
                assets__[i.id] = {
                    ...assets__[i.id],
                    ...({
                        id: i.id,
                        key: i.key,
                        name: i.name,
                        extensionData: i.extensionData,
                        product: i.product,
                        tagId: i.tagId,
                    }),
                };
            }

            return assets__;
        });
    });

    // receives AssetExtensionDataState messages
    useHubMethod(connection, 'AssetExtensionDataState', messages => {
        setAssets(assets_ => {
            const assets__ = { ...assets_ };

            for (var i of messages) {
                assets__[i.assetId] = {
                    ...assets__[i.assetId],
                    extensionData: {
                        ...assets__[i.assetId]?.extensionData,
                        [i.key]: i.value,
                    }
                };
            };

            return assets__;
        });
    });

    // receives OwnerState messages
    useHubMethod(connection, 'OwnerState', messages => {
        setOwners(owners_ => {
            const owners__ = { ...owners_ };

            for (var i of messages) {
                owners__[i.id] = {
                    ...owners__[i.id],
                    ...({
                        id: i.id,
                        key: i.key,
                        name: i.name,
                        extensionData: i.extensionData,
                        assetId: i.assetId,
                    }),
                };
            }

            return owners__;
        });
    });

    // receives OwnerExtensionDataState messages
    useHubMethod(connection, 'OwnerExtensionDataState', messages => {
        setOwners(owners_ => {
            const owners__ = { ...owners_ };

            for (var i of messages) {
                owners__[i.ownerId] = {
                    ...owners__[i.ownerId],
                    extensionData: {
                        ...owners__[i.ownerId]?.extensionData,
                        [i.key]: i.value,
                    }
                };
            };

            return owners__;
        });
    });

    // receives MeterState messages
    useHubMethod(connection, 'MeterState', messages => {
        setMeters(meters_ => {
            const meters__ = { ...meters_ };

            for (var i of messages) {
                meters__[i.id] = {
                    ...meters__[i.id],
                    ...({
                        id: i.id,
                        monitors: i.monitors.map(j => ({
                            id: j.id,
                            value: j.value
                        }))
                    }),
                };
            }

            return meters__;
        });
    });

    if (region && tags && assets && owners && meters) {
        return <RegionView region={region} tags={tags} assets={assets} owners={owners} meters={meters} />;
    } else {
        return <Loading />;
    }
};

/**
 * Page that displays a region.
 * @param {any} param0
 */
export const RegionView = ({ region, tags, assets, owners, meters }) => {
    const config = useConfig();
    const auth = useAuth();
    const user = useUser();
    const account = useAccount();
    const api = useApi();

    const [fabShow, setFabShow] = useState(false);
    const [fabOpen, setFabOpen] = useState(false);

    const [displayProducts, setDisplayProducts] = useState(true);

    // maintain state for the currently selected scene
    const [sceneId, setSceneId] = useState("");
    useEffect(() => {
        if (sceneId && region.scenes.some(i => i.id === sceneId) === false) {
            setSceneId("");
        }
    }, [region.id])

    // check that the user has admin permission before showing the speed dial
    useEffect(async () => {
        setFabShow(await hasAccountAdminAsync(auth, user, account?.selectedId ? { id: account.selectedId } : null, api));
    }, [auth, user, account, api])

    // combines the tags with the assets to produce the final data set
    const [tagData, setTagData] = useState(null);
    useEffect(() => {
        if (region && tags) {
            if (region && tags && assets) {
                const tags_ = Object.values(tags);
                const assets_ = Object.values(assets);
                const owners_ = Object.values(owners);

                // calculate minimum locate time
                let minLocateTime_ = {};
                [minLocateTime_.hours, minLocateTime_.minutes, minLocateTime_.seconds] = [...region.tagExpireTime.split(':')];
                const minLocateTime = moment.utc().subtract(minLocateTime_).toDate();

                let temp = tags_
                    .filter(tag => tag.locateTime > minLocateTime)
                    .map(tag => ({
                        id: tag.id,
                        key: tag.key,
                        x: tag.x,
                        y: tag.y,
                        z: tag.z,
                        locateTime: tag.locateTime,
                        asset: assets_.filter(asset => asset.tagId && asset.tagId === tag.id).map(asset => ({
                            id: asset.id,
                            key: asset.key,
                            name: asset.name,
                            extensionData: asset.extensionData,
                            product: asset.product,
                            owner: owners_.filter(owner => owner.assetId && owner.assetId === asset.id).map(owner => ({
                                id: owner.id,
                                key: owner.key,
                                name: owner.name,
                                extensionData: owner.extensionData,
                            }))[0] ?? null,
                        }))[0] ?? null,
                        zone: tag.zoneId !== null ? region.zones.find(zone => zone.id === tag.zoneId) ?? null : null,
                    }));

                if (region.displayMode === 'assets') {
                    temp = temp.filter(tag => tag.asset);
                    if (displayProducts === false) {
                        temp = temp.filter(tag => tag.asset.product === null)
                    }
                } else if (region.displayMode === 'owners') {
                    temp = temp.filter(tag => tag.asset && tag.asset.owner);
                }

                setTagData(temp);
            } else {
                setTagData([]);
            }
        }
    }, [region.id, tags, assets, displayProducts]);

    // show loading screen until tags are available
    if (tagData === null) {
        return <Loading />;
    }

    /**
     * Exports the given region.
     * @param {any} regionId
     */
    const openTemplateAsync = async () => {
        const headers = {
            'Accept': 'application/json',
        };

        if (auth) {
            headers['Authorization'] = auth.oidc.oidcUser?.token_type ? auth.oidc.oidcUser.token_type + ' ' + auth.oidc.oidcUser.access_token : null
        }

        const res = await fetch(urlJoin(config.Api.BaseUri, `v1/regions/${region.id}/template`), {
            headers: headers,
        });

        const blob = new Blob([JSON.stringify(await res.json(), null, 4)], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = region.id + '.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const readAsTextAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => { resolve(reader.result); };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    }

    const saveTemplateAsync = async (file) => {
        const text = await readAsTextAsync(file);
        const body = JSON.parse(text);

        const headers = {
            'Accept': 'application/json',
            'Content-Type': file.type,
        };

        if (auth) {
            headers['Authorization'] = auth.oidc.oidcUser?.token_type ? auth.oidc.oidcUser.token_type + ' ' + auth.oidc.oidcUser.access_token : null
        }

        const res = await fetch(urlJoin(config.Api.BaseUri, `v1/regions/${region.id}/template`), {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        });
    };

    if (region === null) {
        return <></>;
    }

    return (
        <Box p={0}>
            <Grid container spacing={2} alignItems="stretch" sx={{ height: 1 }}>
                <Grid item xs={12}>
                    <MeterList region={region} meters={meters} />
                </Grid>
                <Grid item xs={12} md={7}>
                    <SceneCard region={region} tags={tagData} setDisplayProducts={setDisplayProducts} displayProducts={displayProducts} />
                </Grid>
                <Grid item xs={12} md={5}>
                    <TableCard region={region} tags={tagData} />
                </Grid>
            </Grid>
            {fabShow ? (
                <FileSelectContext onSelect={files => files.length > 0 ? saveTemplateAsync(files[0]) : null}>
                    {startTemplateImport => (
                        <SpeedDial
                            ariaLabel="System"
                            sx={{ position: 'fixed', bottom: 16, right: 16 }}
                            color="secondary"
                            icon={<SpeedDialIcon />}
                            onClose={() => setFabOpen(false)}
                            onOpen={() => setFabOpen(true)}
                            open={fabOpen}
                            FabProps={{ size: 'small' }}>
                            <SpeedDialAction
                                icon={<FileDownloadOutlinedIcon />}
                                tooltipTitle="Export"
                                tooltipOpen
                                onClick={async () => { setFabOpen(false); await openTemplateAsync(); }}
                            />
                            <SpeedDialAction
                                icon={<FileUploadOutlinedIcon />}
                                tooltipTitle="Import"
                                tooltipOpen
                                onClick={async () => { setFabOpen(false); startTemplateImport(); }}
                            />
                        </SpeedDial>
                    )}
                </FileSelectContext>) : null}
        </Box>
    );
};

/**
 * Renders the region scene card.
 * @param {any} param0
 */
const SceneCard = ({ region, tags, setDisplayProducts, displayProducts }) => {
    const [sceneId, setSceneId] = useState("");
    const scene = region.scenes.find(i => i.id === sceneId);

    return (
        <Card>
            <CardContent>
                <Box sx={{ position: 'relative' }}>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            {
                                region.displayMode === 'assets' ? (
                                    <Box sx={{  left: 1, top: 8, zIndex: 1 }}>
                                        <ProductsToggle setDisplayProducts={setDisplayProducts} displayProducts={displayProducts} />
                                    </Box>
                                ) : null
                            }
                            {
                                region.scenes.length > 0 ? (
                                    <Box sx={{  right: 8, top: 8, zIndex: 1 }}>
                                        <RegionSceneToggle region={region} sceneId={sceneId} onSceneIdChange={setSceneId} />
                                    </Box>
                                ) : null
                            }
                        </Stack>
                        <Box sx={{ aspectRatio: '4 / 3' }}>
                            {
                                tags ? (
                                    scene ?
                                        <RegionView3D region={region} scene={scene} tags={tags} style={{ width: '100%', height: '100%' }} /> :
                                        <RegionView2D region={region} tags={tags} style={{ width: '100%', height: '100%' }} />
                                ) : null
                            }
                        </Box>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
}

/**
 * Renders the card into which the region table goes.
 * @param {any} param0
 */
const TableCard = ({ region, tags }) => {
    return (
        <Card sx={{ height: 1 }}>
            <CardContent sx={{ height: 1, minHeight: 400 }}>
                <TagTable region={region} tags={tags} />
            </CardContent>
        </Card>
    );
};

/**
 * Provides a toggle switch to choose to exclude products from being displayed
 * @param {any} param0
 */
const ProductsToggle = ({setDisplayProducts, displayProducts}) => {
    return (
        <ToggleButton
            value="check"
            selected={displayProducts}
            onChange={() => {
                setDisplayProducts(!displayProducts)
            }}
            sx={{
                '&:not(.Mui-selected)': {
                    textDecoration: 'line-through'
                }
            }}
        >
            Display Products
        </ToggleButton>
        
    );
};

/**
 * Provides a toggle button to switch between scenes.
 * @param {any} param0
 */
const RegionSceneToggle = ({ region, sceneId, onSceneIdChange }) => {
    return (
        <ToggleButtonGroup exclusive value={sceneId} onChange={(ev, value) => value !== null ? onSceneIdChange(value) : null}>
            <ToggleButton value={""}>2D View</ToggleButton>
            {
                region.scenes.map(scene =>
                    <ToggleButton key={scene.id} value={scene.id}>
                        {scene.name}
                    </ToggleButton>
                )
            }
        </ToggleButtonGroup>
    );
};

/**
 * Watches the monitor and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const evalMonitorColorAsync = async (monitor) => {
    return {
        primary: await evalColorExpressionAsync(monitor.primaryColorExpressionType, monitor.primaryColorExpression, monitor.value),
        secondary: await evalColorExpressionAsync(monitor.secondaryColorExpressionType, monitor.secondaryColorExpression, monitor.value),
    };
}

/**
 * Watches the monitor and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const useMonitorColor = (monitor) => {
    return usePromise(evalMonitorColorAsync, [monitor]);
}

/**
 * Wheel control within a meter display.
 * @param {any} param0
 */
const MeterWheel = ({ meter, meterState }) => {
    const theme = useTheme();
    const monitors = useMemo(() => meter.monitors.filter(monitor => monitor.showOnWheel).sort((m1, m2) => m1.displayOrder ?? 0 - m2.displayOrder ?? 0, [meter]));
    const colors = usePromise(m => Promise.all(m.map(evalMonitorColorAsync)), [monitors]);
    const values = meterState.monitors.reduce((o, m) => ({ ...o, [m.id]: m.value }), {});

    const options = useMemo(() => {
        const series = monitors.map(monitor => values[monitor.id] ?? 0);
        const labels = monitors.map(monitor => monitor.name);

        return {
            series: series,
            labels: labels,
            colors: colors.map(i => i.primary ?? theme.palette.primary.main),
            chart: {
                type: 'donut',
                sparkline: {
                    enabled: true
                },
                animations: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (value, { seriesIndex }) => series[seriesIndex] ?? '',
                distributed: true,
                style: {
                    fontSize: theme.typography.meterLabel.fontSize,
                    fontFamily: theme.typography.meterLabel.fontFamily,
                    fontWeight: theme.typography.meterLabel.fontWeight,
                    colors: colors.map(i => i.secondary ?? theme.palette.getContrastText(i.primary ?? theme.palette.primary.main))
                },
                textAnchor: 'middle',
                offsetX: 0,
                offsetY: 0,
                dropShadow: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    customScale: 0.85,
                    donut: {
                        size: '50%',
                        labels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: theme.typography.meterTotal.fontSize,
                                fontFamily: theme.typography.meterTotal.fontFamily,
                                fontWeight: theme.typography.meterTotal.fontWeight,
                                color: theme.palette.common.white,
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Total',
                                formatter: w => w.globals.seriesTotals.reduce((a, b) => a + b, 0),
                            }
                        }
                    }
                },
            },
            stroke: {
                show: false,
                width: 0
            },
            tooltip: {
                enabled: false,
            }
        };
    }, [meter.id, meterState, theme, monitors, colors])

    return (
        <div style={{ height: '150px', width: '150px' }}>
            <Chart style={{ width: '100%', height: '100%' }} options={options} series={options.series} type="donut" height="100%" />
        </div>
    );
};

/**
 * Index display within a meter.
 * @param {any} param0
 */
const MeterIndex = ({ meter }) => {
    if (meter) {
        return (
            <List dense variant="meter-index">
                {
                    meter.monitors ? meter.monitors.sort((m1, m2) => m1.displayOrder ?? 0 - m2.displayOrder ?? 0).map(monitor => (
                        <MeterIndexItem key={monitor.id} monitor={monitor} />
                    )) : null
                }
            </List>
        );
    } else {
        return null;
    }
};

/**
 * Item within a meter index display.
 * @param {any} param0
 */
const MeterIndexItem = ({ monitor }) => {
    const theme = useTheme();
    const { primary } = useMonitorColor(monitor);

    if (monitor) {
        return (
            <ListItem dense disableGutters>
                <ListItemIcon p={0} m={0}>
                    <CircleTwoToneIcon sx={{ color: primary }} />
                </ListItemIcon>
                <ListItemText
                    primary={monitor.name}
                    primaryTypographyProps={{ noWrap: true }}
                />
            </ListItem>
        );
    } else {
        return <></>;
    }
};

/**
 * Displays a single meter box.
 * @param {any} param0
 */
const MeterListItem = ({ meter, meterState }) => {
    const theme = useTheme();

    return (
        <Box key={meter.id} sx={{ maxWidth: `${theme.breakpoints.values.sm * .7}px`, minWidth: `${theme.breakpoints.values.sm * .7}px` }}>
            <Meter meter={meter} meterState={meterState} />
        </Box>
    );
};

/**
 * Displays the list of meters associated with the specified region.
 * @param {any} param0
 */
const MeterList = ({ region, meters }) => {
    if (region && region.meters.length > 0) {
        return (
            <Suspense fallback={null}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch', gap: 2 }}>
                    {region.meters.map(meter => <MeterListItem key={meter.id} meter={meter} meterState={meters[meter.id]} />)}
                </Box>
            </Suspense>
        );
    } else {
        return <></>;
    }
};

/**
 * Watches the monitor and returns the computed color value of the alarm.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const useMonitorAlarmColor = (monitor) => {
    return usePromise(async (monitor) => ({
        primary: await evalColorExpressionAsync(monitor.primaryAlarmColorExpressionType, monitor.primaryAlarmColorExpression, monitor.value),
        secondary: await evalColorExpressionAsync(monitor.secondaryAlarmColorExpressionType, monitor.secondaryAlarmColorExpression, monitor.value),
    }), [monitor]);
}

/**
 * Index display within a meter.
 * @param {any} param0
 */
const MeterAlarm = ({ meter }) => {
    if (meter) {

        // first monitor with an alarm state defines alarm state of meter
        const faultedMonitor = meter.monitors.sort(i => i.displayOrder).find(i => i.alarmUtcTime) ?? null;

        return (
            <>
                {
                    faultedMonitor ? (
                        <MeterAlarmItem monitor={faultedMonitor} />
                    ) : null
                }
            </>
        );
    } else {
        return null;
    }
};

/**
 * Item within a meter index display.
 * @param {any} param0
 */
const MeterAlarmItem = ({ monitor }) => {
    const colors = useMonitorAlarmColor(monitor);

    if (monitor) {
        return (
            <div className="alarm-item" style={{ backgroundColor: colors.primary.done ? colors.primary.value : null }}>
                <span style={{ color: colors.secondary.done ? colors.secondary.value : null }}>{monitor.alarmText ?? "ALARM"}</span>
            </div>
        );
    } else {
        return null;
    }
};

/**
 * Displays a single meter on the dashboard.
 * @param {any} param0
 */
const Meter = ({ meter, meterState }) => {
    return (
        <Card variant="meter">
            <CardHeader title={meter.name} />
            <CardContent>
                <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} sx={{ width: '100%' }}>
                    <Box>{meter ? <MeterWheel meter={meter} meterState={meterState} /> : null}</Box>
                    <Box sx={{ width: '240px' }}>{meter ? <MeterIndex meter={meter} /> : null}</Box>
                </Stack>
                <Box sx={{ width: '100%' }}>{meter ? <MeterAlarm meter={meter} /> : null}</Box>
            </CardContent>
        </Card>
    );
};

/**
 * Renders the appropriate content for a custom expression field and tag.
 * @param {any} param0
            */
const TagTableFieldDisplay = ({ field, tag }) => {
    const [value, setValue] = useState(null);
    useEffect(() => (async (field, tag) => setValue(await evalFormatExpressionAsync(field.formatExpressionType, field.formatExpression, tag)))(field, tag), [field, tag]);
    return <span>{value}</span>;
};

/**
 * Maps the RegionField.Type values to their render and value implementations. render() is used to output the HTML for the value of the field, while value() returns a sortable version.
 * */
const fieldTypeMap = {
    expression: {
        render: (tag, field) => (<div><TagTableFieldDisplay field={field} tag={tag} /></div>),
        value: (tag, field) => fieldExprExec(tag, field),
    },
    location: {
        render: (tag, field) => (<div>{tag.zone && tag.zone.name ? <div className={`zone-header ${tag.zone.color}`}>{tag.zone.name}</div> : "N/A"}</div>),
        value: (tag, field) => tag.zone ? tag.zone.name : null,
    },
    name: {
        render: (tag, field) => (<div>{tag.asset?.owner?.name ?? tag.asset?.owner?.key ?? tag.asset?.name ?? tag.asset?.key ?? tag.key}</div>),
        value: (tag, field) => tag.asset?.owner?.name ?? tag.asset?.owner?.key ?? tag.asset?.name ?? tag.asset?.key ?? tag.key,
    },
    activity: {
        render: (tag, field) => (<div><span>{moment.utc(tag.locateTime).toDate().toLocaleDateString()}</span><br /><span>{moment.utc(tag.locateTime).toDate().toLocaleTimeString()}</span></div>),
        value: (tag, field) => moment.utc(tag.locateTime ?? 0).unix(),
    }
}

/**
 * Displays the table of active tags within the region.
 * @param {any} param0
            */
const TagTable = ({ region, tags }) => {
    const [data, setData] = useState([]);
    const [sort, setSort] = useState({ by: null, reverse: false });
    const { selection, setSelection } = useSelectionsContext();

    /**
     * Sets the selected tag ID, or unsets it if already selected.
     * @param {any} tagId
            */
    const setSelectedTagId = (tagId) => {
        if (selection && selection.type === 'tag' && selection.item === tagId) {
            setSelection(null);
        } else {
            setSelection({ type: 'tag', item: tagId });
        }
    }

    /**
     * Changes the currently sorted field by index. If called on the field that is already sorted, reverses the sort.
     * @param {any} by
            */
    const changeSort = by => {
        setSort({ by: by, reverse: sort.by === by ? !sort.reverse : false });
    };

    // set of fields to be used for this region
    const fields = useMemo(() => region && region.fields && region.fields.length > 0 ? region.fields.filter(f => f.showOnTable) : defaultRegionFields, [region]);

    // compares the two values
    const compareFunc = (a, b) => {
        if (a === b) {
            return 0;
        } else if (a === null) {
            return -1;
        } else if (b === null) {
            return 1;
        } else if (a > b) {
            return -1;
        } else if (b > a) {
            return 1;
        } else {
            return 0;
        }
    };

    // transforms the current tag set into the final sorted data set.
    useEffect(() => {
        if (tags) {
            let temp = [...tags];

            if (sort.by !== null && sort.by >= 0) {
                const field = fields[sort.by];
                if (field) {
                    const valueFunc = fieldTypeMap[field.type].value;
                    if (valueFunc) {
                        temp = temp.sort((e1, e2) => compareFunc(valueFunc(e1, field), valueFunc(e2, field)));
                    }
                }
            } else {
                temp = temp.sort((e1, e2) => compareFunc(e1.key, e2.key));
            }

            if (sort.reverse) {
                temp = temp.reverse();
            }

            setData(temp);
        } else {
            setData([]);
        }
    }, [region, tags, sort.by, sort.reverse]);

    const selectedTagId = selection?.type === 'tag' ? selection.item : null;
    const selectedZoneId = selection?.type === 'zone' ? selection.item : null;

    return (
        <Box sx={{ height: 1 }}>
            <TableContainer sx={{ height: 1, maxHeight: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                fields.map((field, index) => (
                                    <TableCell
                                        key={index}
                                        title={field.name}
                                        sortDirection={sort.reverse ? 'desc' : 'asc'}
                                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <TableSortLabel
                                            active={sort.by === index}
                                            direction={sort.reverse ? 'desc' : 'asc'}
                                            onClick={ev => changeSort(index)}>
                                            {field.name}
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map(tag => {
                                try {
                                    if (region) {
                                        const selected = selectedTagId ? (selectedTagId === tag.id) : (tag.zone && selectedZoneId === tag.zone.id);

                                        return (
                                            <TableRow key={tag.id} hover selected={selected} onClick={ev => setSelectedTagId(tag.id)} >
                                                {
                                                    fields.map((field, index) => (
                                                        <TagTableCell key={index} field={field} tag={tag}>
                                                            {fieldTypeMap[field.type].render(tag, field)}
                                                        </TagTableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                } catch (e) {
                                    console.log(e);
                                    return null;
                                }
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const TagTableCell = ({ field, tag, children }) => {
    const [color, setColor] = useState({});
    useEffect(() => (async (field, tag) => setColor(await getFieldColorsAsync(field, tag)))(field, tag), [field, tag]);

    return (
        <TableCell style={{ color: color.secondary, backgroundColor: color.primary }}>
            {children}
        </TableCell>
    );
};
