import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import urlJoin from 'proper-url-join';
import { useConfig } from '../contexts/config';
import { useApiFetch } from '../helpers/api-fetch';
import { useAccount } from '../contexts/account';
import rfcosImg from '../assets/images/rfcos.webp';

export const ConfigPage = () => {
    const config = useConfig();
    const apiFetch = useApiFetch();
    const account = useAccount();

    const openRfcOs = async () => {
        try {
            const response = await apiFetch(`v1/accounts/${account.selectedId}/rfcOsLoginToken`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json'
                }
            });

            if (response.ok !== true) {
                if (response.headers['Content-Type'] === 'application/json') {
                    throw new Error(response.json());
                } else {
                    throw new Error(response.statusText);
                }
            }

            const loginToken = await response.json();
            if (loginToken && loginToken.value) {
                window.open(urlJoin(config.RfcOs.BaseUri, `web-ui/login-account?token=${loginToken.value}`));
            }

        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Stack alignItems="center" justifyContent="center" spacing={4} sx={{ mt: 4 }} >
            <Typography variant="h3" component="h3">
                Account Configuration
            </Typography>
            <Typography variant="h5" component="h5">
                Account configuration is currently handled through RFC OS.
            </Typography>
            <Button size="large" variant="outlined" startIcon={<img width="24" height="24" src={rfcosImg} />} onClick={() => openRfcOs()}>
                Launch RFC OS
            </Button>
        </Stack>
    );
};
