import { Navigate } from "react-router-dom";
import { Loading } from "../components/loading";
import { useAccount } from "../contexts/account";
import { useApiAsync } from "../contexts/api";
import { NotFoundPage } from "./notfound";

/**
 * Loads the regions for the current user and account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const getRegionsAsync = async (api, account) => {
    if (account && account.id) {
        const { data } = await api.all('regions').get({ filter: `equals(account.id,'${account.id}')` });
        return data;
    } else {
        return [];
    }
}

export const DashboardPage = () => {
    const { selectedId: accountId } = useAccount() ?? {};
    const { data, isPending } = useApiAsync((api, accountId) => getRegionsAsync(api, { id: accountId }), [accountId])

    if (data && data.length > 0) {
        return <Navigate to={`/regions/${data[0].id}`} replace />;
    } else if (isPending) {
        return <Loading />;
    } else {
        return <NotFoundPage />;
    }
};
