import React, { createContext, useCallback, useContext } from 'react';
import JsonApi from 'devour-client';
import urlJoin from 'proper-url-join';
import { useAsync } from 'react-async';
import { useAuth } from './auth';
import { useConfig } from './config';

const ApiContext = createContext(null);
ApiContext.displayName = 'ApiContext';

export const useApi = () => useContext(ApiContext);

export const ApiContextProvider = ({ children }) => {
    const config = useConfig();
    const auth = useAuth();

    // configuration not yet loaded
    if (config == null) {
        return;
    }

    // JSON API configuration options
    const options = {
        apiUrl: urlJoin(config.Api.BaseUri, 'v1'),
    }

    // apply authentication if enabled and authenticated
    if (auth) {
        if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
            options.bearer = auth.oidc.oidcUser.access_token;
        }
    }

    // generate new JSON API client
    const api = new JsonApi(options);

    api.define('account', {
        name: '',
        assetExtensionFields: {},
        ownerExtensionFields: {},
        metadata: {},
        agents: {
            jsonApi: 'hasMany',
            type: 'agent'
        },
        services: {
            jsonApi: 'hasMany',
            type: 'service'
        },
        regions: {
            jsonApi: 'hasMany',
            type: 'region'
        },
        tags: {
            jsonApi: 'hasMany',
            type: 'tag'
        },
        assets: {
            jsonApi: 'hasMany',
            type: 'asset'
        },
        owners: {
            jsonApi: 'hasMany',
            type: 'owner'
        },
        userRoles: {
            jsonApi: 'hasMany',
            type: 'userAccountRole'
        },
        inventories: {
            jsonApi: 'hasMany',
            type: 'inventory'
        },
        products: {
            jsonApi: 'hasMany',
            type: 'product'
        }
    });

    api.define('user', {
        profile: {},
        metadata: {},
        accountRoles: {
            jsonApi: 'hasMany',
            type: 'userAccountRole'
        }
    });

    api.define('userAccountRole', {
        type: 0,
        user: {
            jsonApi: 'hasOne',
            type: 'user'
        },
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        metadata: {}
    });

    api.define('agent', {
        type: '',
        name: '',
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        }
    });

    api.define('service', {
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        }
    });

    api.define('asset', {
        key: '',
        name: '',
        quantity: 0,
        active: 1,
        extensionData: {},
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        tag: {
            jsonApi: 'hasOne',
            type: 'tag'
        },
        owner: {
            jsonApi: 'hasOne',
            type: 'owner'
        },
        product: {
            jsonApi: 'hasOne',
            type: 'product'
        }
    });

    api.define('owner', {
        key: '',
        name: '',
        extensionData: {},
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        asset: {
            jsonApi: 'hasOne',
            type: 'asset'
        }
    });

    api.define('image', {
        key: '',
        content: '',
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        }
    });

    api.define('contactList', {
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        }
    });

    api.define('region', {
        name: '',
        displayMode: '',
        tagExpireTime: '',
        tagPrimaryColorExpressionType: null,
        tagPrimaryColorExpression: '',
        tagSecondaryColorExpressionType: null,
        tagSecondaryColorExpression: '',
        tagImageKeyExpressionType: null,
        tagImageKeyExpression: '',
        fields: [],
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        zones: {
            jsonApi: 'hasMany',
            type: 'zone'
        },
        zoneGroups: {
            jsonApi: 'hasMany',
            type: 'zoneGroup'
        },
        tags: {
            jsonApi: 'hasMany',
            type: 'tag'
        },
        meters: {
            jsonApi: 'hasMany',
            type: 'meter'
        },
        images: {
            jsonApi: 'hasMany',
            type: 'regionImage'
        },
        scenes: {
            jsonApi: 'hasMany',
            type: 'regionScene'
        }
    });

    api.define('regionImage', {
        content: '',
        corner1X: 0,
        corner1Y: 0,
        corner2X: 0,
        corner2Y: 0,
        metadata: {},
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        }
    });

    api.define('regionScene', {
        name: '',
        metadata: {},
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        },
        objects: {
            jsonApi: 'hasMany',
            type: 'regionSceneObject'
        }
    });

    api.define('regionSceneObject', {
        content: '',
        position: [],
        rotation: [],
        scale: [],
        metadata: {},
        scene: {
            jsonApi: 'hasOne',
            type: 'regionScene'
        }
    });

    api.define('zone', {
        name: '',
        corner1X: 0,
        corner1Y: 0,
        corner1Z: 0,
        corner2X: 0,
        corner2Y: 0,
        corner2Z: 0,
        metadata: {},
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        },
        tags: {
            jsonApi: 'hasMany',
            type: 'tag'
        },
        groups: {
            jsonApi: 'hasMany',
            type: 'zoneGroup'
        }
    });

    api.define('tag', {
        key: '',
        x: 0,
        y: 0,
        z: 0,
        locateTime: 0,
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        },
        zone: {
            jsonApi: 'hasOne',
            type: 'zone'
        }
    });

    api.define('zoneGroup', {
        name: '',
        eventMaxAge: '',
        metadata: {},
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        },
        zones: {
            jsonApi: 'hasMany',
            type: 'zone'
        },
        tagEvents: {
            jsonApi: 'hasMany',
            type: 'zoneGroupTagEvent'
        },
        monitors: {
            jsonApi: 'hasMany',
            type: 'monitor'
        }
    });

    api.define('zoneGroupTagEvent', {
        timestamp: '',
        type: '',
        metadata: {},
        group: {
            jsonApi: 'hasOne',
            type: 'zoneGroup'
        },
        tag: {
            jsonApi: 'hasOne',
            type: 'tag'
        },
        asset: {
            jsonApi: 'hasOne',
            type: 'asset'
        },
        owner: {
            jsonApi: 'hasOne',
            type: 'owner'
        }
    });

    api.define('meter', {
        name: '',
        type: '',
        alarmUtcTime: '',
        metadata: {},
        region: {
            jsonApi: 'hasOne',
            type: 'region'
        },
        monitors: {
            jsonApi: 'hasMany',
            type: 'monitor'
        }
    });

    api.define('monitor', {
        name: '',
        type: '',
        value: 0,
        eventMaxAge: '',
        includeExpressionType: '',
        includeExpression: '',
        displayOrder: 0,
        showOnWheel: false,
        showOnCards: false,
        primaryColorExpressionType: '',
        primaryColorExpression: '',
        secondaryColorExpressionType: '',
        secondaryColorExpression: '',
        alarmMinValue: 0,
        alarmMaxValue: 0,
        alarmSetDelay: '',
        alarmUnsetDelay: '',
        alarmText: '',
        alarmPrimaryColorExpressionType: '',
        alarmPrimaryColorExpression: '',
        alarmSecondaryColorExpressionType: '',
        alarmSecondaryColorExpression: '',
        alarmUtcTime: '',
        metadata: {},
        meter: {
            jsonApi: 'hasOne',
            type: 'meter'
        },
        zoneGroup: {
            jsonApi: 'hasOne',
            type: 'zoneGroup'
        },
        events: {
            jsonApi: 'hasMany',
            type: 'monitorGroupEvent'
        }
    });

    api.define('monitorEvent', {
        timestamp: '',
        type: '',
        value: 0,
        metadata: {},
        group: {
            jsonApi: 'hasOne',
            type: 'monitor'
        }
    });

    api.define('inventory', {
        name: '',
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        inventoryFields: {
            jsonApi: 'hasMany',
            type: 'inventoryField'
        },
        products: {
            jsonApi: 'hasMany',
            type: 'product'
        }
    });

    api.define('inventoryField', {
        name: '',
        metadata: {}
    });

    api.define('product', {
        name: '',
        reorderPoint: '',
        metadata: {},
        account: {
            jsonApi: 'hasOne',
            type: 'account'
        },
        assets: {
            jsonApi: 'hasMany',
            type: 'asset'
        },
    });

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
};

const asyncFunc = async ({ api, func, args }) => {
    return await func.apply(null, [api, ...args]);
};

const equalsArray = (a, b) => {
    // might point to same array
    if (a === b)
        return true;

    // lengths must equal
    if (a.length !== b.length)
        return false;

    // check every element
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
};

const watchFn = (props, prevProps) => {
    return !equalsArray(props.args, prevProps.args);
}

export const useApiAsync = (func, args) => {
    const api = useApi();
    return useAsync({ promiseFn: asyncFunc, api: api, func: func, args: args ?? [], watchFn: watchFn });
};
