import React from "react";
import { useSelectionsContext } from "../contexts/selection";

export function TagZone({
    zone,
    tags,
    offset,
    scale,
}) {
    const { selection, setSelection } = useSelectionsContext();

    let selectedZoneId = null;
    if (selection?.type === 'zone' && selection.item) {
        selectedZoneId = selection.item;
    } else if (selection?.type === 'tag' && selection?.item && tags) {
        const selectedTag = tags[selection.item];
        if (selectedTag && selectedTag.zoneId) {
            selectedZoneId = selectedTag.zoneId;
        }
    }

    const setSelectedZoneId = zoneId => {
        if (selection && selection.type === 'zone' && selection.item === zoneId) {
            setSelection(null);
        } else {
            setSelection({ type: 'zone', item: zoneId });
        }
    }

    const rect = {
        x1: Math.min(zone.corner1X + offset.x, zone.corner2X + offset.x),
        y1: Math.min(zone.corner1Y + offset.y, zone.corner2Y + offset.y),
        x2: Math.max(zone.corner1X + offset.x, zone.corner2X + offset.x),
        y2: Math.max(zone.corner1Y + offset.y, zone.corner2Y + offset.y),
    }

    const style = {
        left: `${100 * (rect.x1 / scale.width)}%`,
        bottom: `${100 * (rect.y1 / scale.height)}%`,
        width: `${100 * ((rect.x2 - rect.x1) / scale.width)}%`,
        height: `${100 * ((rect.y2 - rect.y1) / scale.height)}%`,
    };

    return (
        <section
            data-zone-id={zone.id}
            onClick={ev => selectedZoneId !== zone.id ? setSelectedZoneId(ev.currentTarget.dataset.zoneId) : setSelectedZoneId(null)}
            className={`tag-zone loaded ${(selectedZoneId === zone.id) ? "selected" : ""}`}
            style={style}>
            <header className={zone.color}>
                {zone.name}
            </header>
        </section>
    );
}
