import React, { createContext, useContext } from 'react';
import { useApiAsync } from './api';
import { useAuth } from './auth';

const UserContext = createContext(null);
UserContext.displayName = 'UserContext';

export const useUser = () => useContext(UserContext);

const UserContextProviderScope = ({ children }) => {
    const { data, isPending } = useApiAsync(api => api.one('user', 'me').get());

    return (
        <UserContext.Provider value={isPending === false && data && data.data ? data.data : null}>
            {children}
        </UserContext.Provider>
    );
}

export const UserContextProvider = ({ children }) => {
    const auth = useAuth();

    if (auth && auth.ready && auth.oidc && auth.oidc.oidcUser) {
        return (
            <UserContextProviderScope>
                {children}
            </UserContextProviderScope>
        );
    } else {
        return (
            <UserContext.Provider value={null}>
                {children}
            </UserContext.Provider>
        );
    }
};
