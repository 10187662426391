import React from 'react';
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { ReactComponent as NotFoundImage } from '../assets/images/404.svg';

export const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Stack alignItems="center" justifyContent="center" spacing={4} sx={{ mt: 4 }}>
            <Typography variant="h3" component="h3">
                Page Not Found
            </Typography>
            <Typography variant="h5" component="h5">
                The requested page could not be found.
            </Typography>
            <NotFoundImage height="200" alt="Not Found" />
            <Button size="large" variant="outlined" startIcon={<HomeIcon />} onClick={() => navigate('/')}>
                Home
            </Button>
        </Stack>
    );
};
