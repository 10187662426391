import React, { createContext, useContext } from 'react';

const ConfigContext = createContext(null);
ConfigContext.displayName = 'ConfigContext';

export const useConfig = () => useContext(ConfigContext);

export const ConfigContextProvider = ({ config, children }) => {
    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};
