import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ConfigPage } from "../pages/config";
import { DashboardPage } from "../pages/dashboard";
import { RegionPage } from "../pages/regions";
import { ForbiddenPage } from "../pages/forbidden";
import { NotFoundPage } from "../pages/notfound";
import { Assets } from "../pages/assets";
import {InventoryPage} from "../pages/inventories";
import { useAuth } from "../contexts/auth";
import { useUser } from "../contexts/user";
import { useAccount } from "../contexts/account";

/**
 * A route that requiers authentication.
 * @param {any} param0
 */
const ProtectedRoute = ({ element: Element, ...rest }) => {
    const auth = useAuth();
    const user = useUser();
    const account = useAccount();
    const params = useParams();

    return auth === null || user !== null && account?.selectedId ? <Element {...params} {...rest} /> : <ForbiddenPage />
}

/**
 * Renders the component associated with the current route.
 * @param {any} param0
 */
export const Routing = ({ }) => {
    return (
        <Routes>
            <Route exact path="/config" element={<ProtectedRoute element={ConfigPage} />} />
            <Route exact path="/assets" element={<ProtectedRoute element={Assets} />} />
            <Route exact path="/regions/:regionId" element={<ProtectedRoute element={RegionPage} />} />
            <Route exact path="/inventories/:inventoryId" element={<ProtectedRoute element={InventoryPage} />} />
            <Route exact path="/" element={<ProtectedRoute element={DashboardPage} />} />
            <Route path="*" element={<ProtectedRoute element={NotFoundPage} />} />
        </Routes>
    );
};
