import usePromise from 'react-promise-suspense';
import { evalExpressionAsync } from './expressions';

/**
 * Names of colors mapped to color values.
 **/
export const defaultColors = {
    'teal': '#44a187',
    'light-blue': '#4882db',
    'dark-teal': '#476f88',
    'purple': '#574376',
    'yellow': '#b8aa5b',
    'majenta': '#a1448d',
    'dark-blue': '#5e48db',
    'brown': '#884f47',
    'green': '#456e40',
    'light-orange': '#b8805b',
}

/**
 * Executes a color expression asynchronously.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const evalColorExpressionAsync = async (type, expression, data) => {
    return evalExpressionAsync(type, expression, data);
}

/**
 * Watches the type, expression and data for a change and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const useColorExpression = (type, expression, data) => {
    return usePromise(evalColorExpressionAsync, [type, expression, data]);
};
