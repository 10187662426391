import React, { Suspense, useContext, useState, createContext } from "react";
import { Container, Box, Link, Typography, CircularProgress, CssBaseline } from '@mui/material';
import { Header } from "./header";
import { Navigator } from "./navigator";
import { useAuth } from "../contexts/auth";
import { useUser } from "../contexts/user";
import { Loading } from "./loading";

const LayoutContext = createContext(null);
LayoutContext.displayName = 'LayoutContext';
export const useLayout = () => useContext(LayoutContext);

/**
 * Displays the Accu-traq copyright notice.
 */
const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            <span><span>Copyright � </span><span><Link color="inherit" href="https://accu-traq.com/">Accu-traq</Link></span><span> {new Date().getFullYear()}</span></span>
        </Typography>
    );
};

/**
 * Main application layout, basic nav bar, header, and main body region.
 * @param {any} param0
 */
export const Layout = ({ children }) => {
    const auth = useAuth();
    const user = useUser();
    const [navOpen, setNavOpen] = useState(false);
    const [title, setTitle] = useState(null);

    const context = {
        setTitle: setTitle,
    };

    return (
        <LayoutContext.Provider value={context}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <CssBaseline />
                {auth === null || user ? (
                    <Navigator open={navOpen} onClose={() => setNavOpen(false)} />
                ) : null}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Header title={title} onNavOpen={() => setNavOpen(true)} />
                    <Box component="main" sx={{ flex: 1, py: 4, px: 4, pb: 0, pt: 0 }}>
                        <Suspense fallback={<Loading />}>
                            {children}
                        </Suspense>
                    </Box>
                </Box>
            </Box>
        </LayoutContext.Provider>
    );
};