import React, { useRef, useMemo, useState, useEffect, useLayoutEffect } from 'react';
import { useWindowResize } from 'beautiful-react-hooks';
import { TagDots } from "./tag-dots";
import { TagZones } from "./tag-zones";
import { TagImages } from "./tag-images";
import { ColorDefs } from './color-defs';
import "./region-view-2d.scss";

/**
 * Two-dimensional overhead view of a region.
 * @param {any} param0
 */
export const RegionView2D = ({ region, tags, style }) => {
    const containerRef = useRef(null);
    const [state, setState] = useState(null);

    /**
     * Determines the bounds for the region.
     * */
    const bounds = useMemo(() => {
        if (region && region.zones) {
            return {
                x1: Math.min(Math.min(...region.zones.map(i => i.corner1X)), Math.min(...region.zones.map(i => i.corner2X))),
                y1: Math.min(Math.min(...region.zones.map(i => i.corner1Y)), Math.min(...region.zones.map(i => i.corner2Y))),
                x2: Math.max(Math.max(...region.zones.map(i => i.corner1X)), Math.max(...region.zones.map(i => i.corner2X))),
                y2: Math.max(Math.max(...region.zones.map(i => i.corner1Y)), Math.max(...region.zones.map(i => i.corner2Y))),
            }
        } else {
            return null;
        }
    }, [region.id]);

    /**
     * Calculates the scale width and height. 
     * */
    const calculateState = () => {
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setState(({ width, height }));
        }
    };

    // we recalculate the state when the window resizes or the parent container
    useWindowResize(calculateState);
    useLayoutEffect(calculateState, []);

    // dimensions of the current viewport
    const scale = useMemo(() => bounds ? {
        ratio: (bounds.y2 - bounds.y1) / (bounds.x2 - bounds.x1),
        width: bounds.x2 - bounds.x1,
        height: bounds.y2 - bounds.y1,
    } : null, [bounds]);

    let boundsStyle = { overflow: 'hidden' };
    let viewStyle = {};
    if (scale && state) {
        boundsStyle = { width: `${100.0 * (state.height / scale.ratio / state.width)}%`, overflow: 'visible' };
        viewStyle = { paddingBottom: `${scale.ratio * 100.0}%`, overflow: 'visible' };
    }

    return (
        <div style={{ ...style, overflow: 'hidden' }}>
            <div ref={containerRef} className="RegionView2D-container">
                <div style={boundsStyle} className="bounds">
                    <div className="view" style={viewStyle}>
                        <div className="inner" style={{ overflow: 'visible' }}>
                            {
                                scale && state ? (
                                    <>
                                        <TagImages region={region} scale={scale} />
                                        <TagZones region={region} scale={scale} />
                                        <TagDots region={region} tags={tags} scale={scale} size={8} />
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ColorDefs />
        </div>
    );
};
