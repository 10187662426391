import React from 'react';
import { CircularProgress, Container } from '@mui/material';

/**
 * Displays the 'loading...' message.
 * */
export const Loading = () => {
    return (
        <Container sx={{ width: 1, height: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
        </Container>
    );
}