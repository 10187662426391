import { useCallback } from 'react';
import { useAsync } from 'react-async';
import urlJoin from 'proper-url-join';
import { useAuth } from '../contexts/auth';
import { useConfig } from '../contexts/config';

export const useApiFetch = () => {
    const config = useConfig();
    const auth = useAuth();

    return useCallback((path, init) => {
        const headers = {
            'Accept': 'application/json',
            ...init?.headers,
        };

        if (auth) {
            headers['Authorization'] = auth.oidc.oidcUser?.token_type ? auth.oidc.oidcUser.token_type + ' ' + auth.oidc.oidcUser.access_token : null
        }

        return fetch(urlJoin(config.Api.BaseUri, path), {
            ...init,
            headers: headers,
        });
    }, [config, auth]);
};

const getJsonAsync = async ({ config, auth, resource, init }) => {
    const headers = {
        ...init?.headers,
        'Accept': 'application/json',
    };

    if (auth) {
        headers['Authorization'] = auth.oidc.oidcUser?.token_type ? auth.oidc.oidcUser.token_type + ' ' + auth.oidc.oidcUser.access_token : null
    }

    const res = await fetch(urlJoin(config.Api.BaseUri, resource), {
        ...init,
        method: 'GET',
        headers: headers,
    });

    if (res.ok) {
        return res;
    } else {
        throw new Error(res.statusText);
    }
};

export const useApiGet = (resource, options) => {
    const config = useConfig();
    const auth = useAuth();
    const getJsonAsyncCb = useCallback(getJsonAsync, [resource, config, auth]);
    const async = useAsync({ promiseFn: getJsonAsyncCb, config: config, auth: auth, resource: resource, ...options });

    return async;
};
