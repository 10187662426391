import jp from 'jsonpath';
import usePromise from 'react-promise-suspense';
import { evalExpressionAsync } from './expressions';
import { evalColorExpressionAsync } from './colors';

/**
 * If the region is lacking fields, these default values will be used for the tag table.
 * */
export const defaultRegionFields = [
    {
        name: 'Location',
        type: 'location'
    },
    {
        name: 'Name',
        type: 'name'
    },
    {
        name: 'Activity',
        type: 'activity'
    }
];

/**
 * If the inventory is lacking fields, these default values will be used for the product table.
 * */
export const defaultInventoryFields = [
    {
        name: 'Name',
        type: 'name'
    },
    {
        name: 'Key',
        type: 'key'
    },
    {
        name: 'Quantity',
        type: 'quantity'
    }
];

/**
 * Evaluates the format expression.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const evalFormatExpressionAsync = async (type, expression, data) => {
    return evalExpressionAsync(type, expression, data);
}

/**
 * Monitors the type, expression and data for changes and returns the resulting formatted string.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const useFormatExpression = (type, expression, data) => {
    return usePromise(evalFormatExpressionAsync, [type, expression, data]);
};

/**
 * Gets the colors of the field, given the specified data.
 * @param {any} field
 * @param {any} data
 */
export const getFieldColorsAsync = async (field, data) => {
    return {
        primary: await evalColorExpressionAsync(field.primaryColorExpressionType, field.primaryColorExpression, data),
        secondary: await evalColorExpressionAsync(field.secondaryColorExpressionType, field.secondaryColorExpression, data)
    };
}

/**
 * Watches the field and data for changes and returns the current color.
 * @param {any} field
 * @param {any} data
 */
export const useFieldColors = (field, data) => {
    return usePromise(getFieldColorsAsync, [field, data]);
};

/**
 * Watches the field and data for changes and returns the current format value.
 * @param {any} field
 * @param {any} data
 */
export const useFieldFormat = (field, data) => {
    return usePromise(evalFormatExpressionAsync, [field.formatExpressionType, field.formatExpression, data]);
};

/**
 * Implements the processing of a field expression.
 * @param {any} item
 * @param {any} field
 */
export const fieldExprExec = (item, field) => {
    try {
        if (field.expressionType === 'raw' && field.expression) {
            return field.expression;
        } else if (field.expressionType === 'jsonPath' && field.expression) {
            return jp.value(item, field.expression);
        } else {
            return null;
        }
    } catch (e) {
        console.log(e);
        return null;
    }
};
