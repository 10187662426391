import React, { useMemo } from "react";
import { useAuth } from "../contexts/auth";
import { TagImage } from "./tag-image";

export const TagImages = ({ region, scale }) => {
    const auth = useAuth();

    const offset = useMemo(() => {
        if (region && region.zones) {
            return {
                x: -(Math.min(Math.min(...region.zones.map(i => i.corner1X)), Math.min(...region.zones.map(i => i.corner2X)))),
                y: -(Math.min(Math.min(...region.zones.map(i => i.corner1Y)), Math.min(...region.zones.map(i => i.corner2Y)))),
            };
        } else {
            return null;
        }
    }, [region.id]);

    let token = null;
    if (auth) {
        if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
            token = auth.oidc.oidcUser.access_token;
        }
    }

    if (region && region.images) {
        return <>
            {
                region.images
                    .map(image => {
                        let content = new URL(image.content);
                        if (token) {
                            content.searchParams.set('access_token', token);
                        }

                        return (
                            <TagImage
                                key={image.id}
                                id={image.id}
                                content={content.href}
                                corner1X={image.corner1X ?? 0}
                                corner1Y={image.corner1Y ?? 0}
                                corner2X={image.corner2X ?? 0}
                                corner2Y={image.corner2Y ?? 0}
                                offset={offset}
                                scale={scale} />
                        )
                    })
            }
        </>;
    } else {
        return null;
    }
};
