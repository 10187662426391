import React from "react";

const colors = [
    { name: "teal", c1: "#44a187", c2: "#54b7b4" },
    { name: "light-blue", c1: "#4882db", c2: "#59ccf9" },
    { name: "dark-teal", c1: "#476f88", c2: "#6298ba" },
    { name: "purple", c1: "#574376", c2: "#8262b4" },
    { name: "yellow", c1: "#b8aa5b", c2: "#d7cd86" },
    { name: "majenta", c1: "#a1448d", c2: "#b7548c" },
    { name: "dark-blue", c1: "#5e48db", c2: "#5978f9" },
    { name: "brown", c1: "#884f47", c2: "#ba7b62" },
    { name: "green", c1: "#456e40", c2: "#8ab462" },
    { name: "light-orange", c1: "#b8805b", c2: "#e6c595" },
];

export function ColorDefs() {
    return (
        <svg>
            <defs>
                {
                    colors.map(({ name, c1, c2 }) => (
                        <linearGradient key={name} id={`svg-gradient-${name}`} >
                            <stop offset="0%" stopColor={c1} stopOpacity="1" />
                            <stop offset="100%" stopColor={c2} stopOpacity="1" />
                        </linearGradient>
                    ))
                }

                <filter x="-16.7%" y="-16.7%" width="133.3%" height="133.3%" filterUnits="objectBoundingBox" id="svg-avatar-filter">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
            </defs>
        </svg>
    );
}
