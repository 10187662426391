import React, { useLayoutEffect, useMemo } from "react";
import { CssBaseline, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from "react-error-boundary";
import usePromise from 'react-promise-suspense';
import { AccountContextProvider } from "./contexts/account";
import { ApiContextProvider } from "./contexts/api";
import { AuthContextProvider } from "./contexts/auth";
import { ConfigContextProvider } from "./contexts/config";
import { SelectionsContextProvider } from "./contexts/selection";
import { UserContextProvider } from "./contexts/user";
import { TaskContextProvider } from "./contexts/task";
import { Layout } from "./components/layout";
import { Routing } from "./components/routing";
import "./app.scss";

const createThemeRecurse = (mode, funcs, index) => {
    const prev = index > 0 ? createThemeRecurse(mode, funcs, index - 1) : null;
    return prev != null ? createTheme(prev, funcs[index](mode, prev)) : createTheme(funcs[index](mode, null));
}

const themeFuncs = [
    (mode, theme) => ({
        palette: {
            mode: mode,
            background: {
                default: '#2c343f'
            },
            primary: {
                main: '#34b2c0'
            },
            secondary: {
                main: '#722040'
            },
            header: {
                main: '#252e3b'
            },
            navigator: {
                main: '#081627'
            }
        },
        typography: {
            fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
            meterLabel: {
                fontSize: '1.5rem',
            },
            meterTotal: {
                fontSize: '2rem',
                fontWeight: 700
            }
        }
    }),
    (mode, theme) => ({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: theme.palette.background.main,
                        background: 'linear-gradient(90deg, rgba(16, 19, 24) 0%, rgba(42, 50, 63) 75%) !important',
                        color: theme.palette.getContrastText(theme.palette.background.default),
                    }
                }
            },
            MuiAppBar: {
                variants: [
                    {
                        props: {
                            variant: 'header'
                        },
                        style: {
                            backgroundColor: theme.palette.header.main,
                            background: 'linear-gradient(90deg, rgb(42, 50, 63) 0%, rgb(27, 37, 53) 100%) !important',
                            color: theme.palette.getContrastText(theme.palette.header.main),
                            '& .MuiTypography-h1': {
                                fontSize: '1.5rem',
                                fontWeight: '1'
                            }
                        }
                    }
                ]
            },
            MuiPaper: {
                variants: [
                    {
                        props: {
                            variant: 'navigator'
                        },
                        style: {
                            backgroundColor: theme.palette.navigator.main,
                            color: theme.palette.getContrastText(theme.palette.navigator.main),
                            minWidth: '300px',
                            '& .MuiTypography-h1': {
                                fontSize: '1.5rem',
                                fontWeight: '1'
                            },
                            '& [class*="MuiList-root"]': {
                                width: '100%',
                            },
                            '& [class*="MuiListItemButton-root"]': {
                                '&.Mui-selected': {
                                    color: '#4fc3f7',
                                },
                            },
                            '& [class*="MuiListItemText-root"]': {
                                fontSize: 14,
                                fontWeight: theme.typography.fontWeightMedium,
                            },
                            '& [class*="MuiListItemIcon-root"]': {
                                color: 'inherit',
                                minWidth: 'auto',
                                marginRight: theme.spacing(2),
                                '& svg': {
                                    fontSize: 20,
                                },
                            }
                        }
                    }
                ]
            },
            MuiList: {
                variants: [
                    {
                        props: {
                            variant: 'meter-index'
                        },
                        style: {
                            '& [class*="MuiListItemIcon-root"]': {
                                minWidth: '28px'
                            }
                        }
                    }
                ],
            },
            MuiSelect: {
                styleOverrides: {
                    root: ({ theme, color, variant }) => ({
                        ...(variant === 'outlined' ? {
                            color: theme.palette[color ?? 'primary'].main,
                            '& [class*="MuiOutlinedInput-notchedOutline"]': {
                                color: theme.palette[color ?? 'primary'].main,
                                borderColor: theme.palette[color ?? 'primary'].main,
                            },
                            '&:hover [class*="MuiOutlinedInput-notchedOutline"]': {
                                color: theme.palette[color ?? 'primary'].light,
                                borderColor: theme.palette[color ?? 'primary'].light,
                            },
                            '&.Mui-focused': {
                                borderColor: 'yellow'
                            }
                        } : null)
                    })
                }
            },
            MuiCard: {
                variants: [
                    {
                        props: {
                            variant: 'meter',
                        },
                        style: {
                            width: '100%',
                            background: "linear-gradient(180deg,#404143,#37383c 60%,#2c2d32 90%)",
                            color: theme.palette.getContrastText('#404143'),
                            '& [class*="MuiCardHeader-root"]': {
                                backgroundColor: '#2a2b2f',
                                color: theme.palette.getContrastText('#2a2b2f'),
                                padding: theme.spacing(1),
                                lineHeight: '1',
                                fontSize: '1rem',
                                fontWeight: 700,
                            },
                            '& [class*="MuiCardHeader-title"]': {
                                fontSize: '1rem',
                                fontWeight: 700,
                            },
                            '& [class*="MuiCardContent-root"]': {
                                width: '100%',
                                padding: 0,
                                paddingBottom: 0,
                                ":last-child": {
                                    paddingBottom: 0
                                }
                            },
                        }
                    }
                ]
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        textAlign: 'center'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:nth-of-type(even)': {
                            backgroundColor: '#f2f2f2',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#c0e6f9',
                            background: 'linear-gradient(115deg, rgba(72, 130, 219, 25%) 0%, rgba(89, 204, 249, 25%) 100%)'
                        },
                    },
                    head: {
                        backgroundColor: '#2a2b2f',
                        color: theme.palette.getContrastText('#2a2b2f'),
                    }
                }
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        color: `${theme.palette.getContrastText('#2a2b2f')} !important`,
                        "&.Mui-active": {
                            color: theme.palette.getContrastText('#2a2b2f'),
                            " .MuiTableSortLabel-icon": {
                                color: `${theme.palette.getContrastText('#2a2b2f')} !important`,
                            },
                        },
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#2a2b2f',
                        color: theme.palette.getContrastText('#2a2b2f'),
                        fontWeight: 'bold',
                        '.Mui-active': {
                            color: theme.palette.getContrastText('#2a2b2f'),
                        },
                        '& [class*="MuiTableSortLabel-root"]': {
                            color: theme.palette.getContrastText('#2a2b2f'),
                            fontWeight: 'bold',
                            '.Mui-active': {
                                color: theme.palette.getContrastText('#2a2b2f'),
                            }
                        }
                    }
                }
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        width: 32,
                        height: 32,
                    },
                },
            },
        }
    })];

/**
 * Displays the application, after the configuration has been loaded.
 * @param {any} param0
 */
const Root = ({ config }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const mode = prefersDarkMode ? 'light' : 'light';
    const theme = useMemo(() => createThemeRecurse(mode, themeFuncs, themeFuncs.length - 1), [mode]);

    return (
        <ConfigContextProvider config={config}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SelectionsContextProvider>
                    <AuthContextProvider>
                        <ApiContextProvider>
                            <UserContextProvider>
                                <AccountContextProvider>
                                    <TaskContextProvider>
                                        <Layout>
                                            <Routing />
                                        </Layout>
                                    </TaskContextProvider>    
                                </AccountContextProvider>
                            </UserContextProvider>
                        </ApiContextProvider>
                    </AuthContextProvider>
                </SelectionsContextProvider>
            </ThemeProvider>
        </ConfigContextProvider>
    );
};

/**
 * Basic root-level error display.
 * @param {any} param0
 */
const Error = ({ error }) => {
    return <div>{error}</div>;
};

/**
 * Renders the application. Accepts a method to obtain the application configuration.
 * @param {any} param0
 */
export const App = ({ getConfigAsync, onLoadComplete }) => {
    const config = usePromise(getConfigAsync, []);
    useLayoutEffect(() => { onLoadComplete(); }, []);

    return (
        <ErrorBoundary fallback={<Error />}>
            <Root config={config} />
        </ErrorBoundary>
    );
};
