export class LinkedListNode {

    constructor(list, value) {
        this._list = list;
        this._value = value;
        this._prev = null;
        this._next = null;
    }

    get list() {
        return this._list;
    }

    get value() {
        return this._value;
    }

    get prev() {
        return this._prev;
    }

    get next() {
        return this._next;
    }

    remove() {
        if (this._prev) {
            this._prev._next = this._next;
        }
        if (this._next) {
            this._next._prev = this._prev;
        }
        if (this._list._head === this) {
            this._list._head = this._next;
        }
        if (this._list._tail === this) {
            this._list._tail = this._prev;
        }

        this._list._size--;
    }

}

export class LinkedList {

    constructor() {
        this._head = null;
        this._tail = null;
        this._size = 0;
    }

    get size() {
        return this._size;
    }

    get head() {
        return this._head;
    }

    get tail() {
        return this._tail;
    }

    prepend(value) {
        const node = new LinkedListNode(this, value, null, this._head);
        node._next = this._head;
        node._prev = null;

        if (this._head) {
            this._head._prev = node;
        }

        this._head = node;
        if (this._tail === null) {
            this._tail = node;
        }

        this._size++;
        return node;
    }

    append(value) {
        const node = new LinkedListNode(this, value);
        node._prev = this._tail;
        node._next = null;

        if (this._tail) {
            this._tail._next = node;
        }

        this._tail = node;
        if (this._head === null) {
            this._head = node;
        }

        this._size++;
        return node;
    }

}
