/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react"
import React, { useState, useEffect } from "react";
import * as moment from 'moment';
import { defaultRegionFields, evalFormatExpressionAsync, getFieldColorsAsync } from "../helpers/table-field";
import './region-tag-popup.scss';

/**
 * Renders the appropriate content for a custom expression field and tag.
 * @param {any} param0
 */
const TagDotFieldExpressionDisplay = ({ field, tag }) => {
    const [format, setFormat] = useState(null);
    useEffect(() => (async (field, tag) => setFormat(await evalFormatExpressionAsync(field.formatExpressionType, field.formatExpression, tag)))(field, tag), [field, tag]);
    const [colors, setColors] = useState({});
    useEffect(() => (async (field, tag) => setColors(await getFieldColorsAsync(field, tag)))(field, tag), [field, tag]);

    return <span><strong style={{ color: colors.primary }}>{field.name}:</strong> <span>{format}</span></span>;
};

/**
 * Maps the RegionField.Type values to their render and value implementations. render() is used to output the HTML for the value of the field, while value() returns a sortable version.
 * */
const fieldTypeMap = {
    expression: {
        render: (tag, field) => <TagDotFieldExpressionDisplay field={field} tag={tag} />
    },
    location: {
        render: (tag, field) => <span><strong>{field.name}:</strong> <span>{tag.zone && tag.zone.name ? <span className={tag.zone.color}>{tag.zone.name}</span> : "N/A"}</span></span>
    },
    name: {
        render: (tag, field) => <span><strong>{field.name}:</strong> <span>{tag.asset?.owner?.name ?? tag.asset?.owner?.key ?? tag.asset?.name ?? tag.asset?.key ?? tag.key}</span></span>
    },
    activity: {
        render: (tag, field) => <span><strong>{field.name}:</strong> <span><span>{moment.utc(tag.locateTime).toDate().toLocaleDateString()}</span> <span>{moment.utc(tag.locateTime).toDate().toLocaleTimeString()}</span></span></span>
    }
}

/**
 * Renders a popup that shows to the side of a tag.
 * @param {any} param0
 */
export const RegionTagPopup = ({ region, tag, color, className }) => {
    // calculate layout information
    const title = tag.asset?.owner?.name ?? tag.asset?.owner?.key ?? tag.asset?.name ?? tag.asset?.key;

    /**
     * Set of fields to be used for this region.
     * */
    const fields = region && region.fields && region.fields.length > 0 ? region.fields.filter(f => f.showOnPopup) : defaultRegionFields;

    return (
        <div
            css={css({
                borderColor: color?.primary
            })}
            className={`RegionTagPopup-container ${color ?? ""} ${className}`}>
            {
                title ? (
                    <div>{title}</div>
                ) : null
            }
            {
                fields.length > 0 ? (
                    <ul>
                        {
                            fields.map((field, index) => (
                                <li key={index}>
                                    {fieldTypeMap[field.type].render(tag, field)}
                                </li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </div>
    );
};
