import React, { useState, useEffect } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { Typography, Grid, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import SettingsIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useAuth } from '../contexts/auth';
import { useUser } from '../contexts/user';
import { useApi } from '../contexts/api';
import { useAccount } from "../contexts/account";
import { AccountMenu } from "./account-menu";

/**
 * Loads the regions for the current user and account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const getRegionsAsync = async (auth, user, account, api) => {
    if (account && account.id) {
        const { data } = await api.all('regions').get({ filter: `equals(account.id,'${account.id}')` });
        return data;
    } else {
        return [];
    }
}

/**
 * Loads the inventory pages for the current user and account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const getInventoryPagesAsync = async (auth, user, account, api) => {
    if (account && account.id) {
        const { data } = await api.all('inventories').get({ filter: `equals(account.id,'${account.id}')` });
        return data;
    } else {
        return [];
    }
}

/**
 * Checks whether the user has configuration access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountRoleAsync = async (auth, user, account, api, type) => {
    if (auth) {
        if (account && account.id) {
            const { data } = await api.all('userAccountRoles').get({ filter: `and(equals(account.id,'${account.id}'),equals(user.id,'${user.id}'))` });

            if (Array.isArray(type)) {
                return data.some(i => type.includes(i.type));
            } else {
                return data.some(i => i.type === type);
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};

/**
 * Checks whether the user has admin access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountAdminAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, 'admin');

/**
 * Checks whether the user has view access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountViewAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, ['view', 'edit']);

/**
 * Checks whether the user has edit access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountFullAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, 'full');

/**
 * Items on the navigation bar.
 */
const navItems = [
    {
        title: 'Regions',
        items: async ({ auth, user, account, api }) => (await getRegionsAsync(auth, user, account, api)).map(i => ({
            route: `/regions/${i.id}`,
            title: i.name,
            icon: <DashboardIcon />,
        })),
    },
    {
        title: 'Account',
        items: async ({ auth, user, account, api }) => (await getInventoryPagesAsync(auth, user, account, api)).map(i => ({
            route: `/inventories/${i.id}`,
            title: i.name,
            icon: <InventoryIcon />
        }))
    },
    {
        title: '',
        items:
        [
            async ({ auth, user, account, api }) => await hasAccountFullAsync(auth, user, account, api) ? ({
                route: '/assets',
                title: 'Assets',
                icon: <PlumbingIcon />,
            }) : null,
            async ({ auth, user, account, api }) => await hasAccountAdminAsync(auth, user, account, api) ? ({
                route: `/config`,
                title: 'Configuration',
                icon: <SettingsIcon />
            }) : null,
        ]
    }
];

const itemProps = {
    px: 3,
    py: '2px',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

export const Navigator = ({ open, onClose }) => {
    const account = useAccount();

    return (
        <Drawer variant="temporary" open={open} sx={{ display: { sm: 'block', xs: 'none' } }} ModalProps={{ keepMounted: true }} PaperProps={{ variant: 'navigator', style: { width: 256 } }} onClose={onClose}>
            <Grid container spacing={1} alignItems="center" p={2}>
                <Grid item xs alignItems="left">
                    <Typography color="inherit" variant="h1" component="h1">
                        <strong>Accu-traq</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton color="inherit" aria-label="close drawer" onClick={onClose} edge="start" size="large">
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {
                account?.selectedId ? (
                    <List component="nav" disablePadding>
                        {navItems.map((item, i) => <NavListRootItem key={i} item={item} onSelect={onClose} />)}
                    </List>
                ) : null
            }
        </Drawer>
    );
};

const NavListRootItem = ({ item, onSelect }) => {
    const { route, title, icon, items } = item;

    return (
        <Box sx={{ bgcolor: items && items.length ? '#101F33' : null }}>
            {
                icon || title ? (
                    <ListItem component="header" sx={{ ...itemProps, py: 2, px: 3 }}>
                        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                        <ListItemText>{title}</ListItemText>
                    </ListItem>
                ) : null
            }
            <NavListItemList items={items} onSelect={onSelect} />
            {item.title === 'Regions' ? <Divider sx={{ mt: 2 }} /> : null}
        </Box>
    );
};

const NavListItemList = ({ items, onSelect }) => {
    const auth = useAuth();
    const user = useUser();
    const api = useApi();
    const { selectedId: accountId } = useAccount() ?? {};

    const [itemList, setItemList] = useState(null);

    useEffect(() => {
        if (items instanceof Function) {
            // function may return an object, or may return a promise, either way, wrap as promise
            setItemList(null);
            (async (context) => setItemList(await Promise.resolve(items(context))))({ auth, user, account: accountId ? { id: accountId } : null, api });
        } else if (items instanceof Array) {
            setItemList(items);
        } else if (items === null || items === undefined) {
            setItemList(null);
        } else {
            console.error('items is not acceptable');
        }
    }, [items, auth, user, api, accountId]);

    return itemList ? itemList.map((item, i) => <NavListItem key={i} item={item} onSelect={onSelect} />) : <NavListItemListLoading />;
};

const NavListItemListLoading = ({ }) => {
    return (
        <ListItem button sx={itemProps} disablePadding>
            <ListItemIcon><CircularProgress size="24px" /></ListItemIcon>
        </ListItem>
    );
};

const NavListItem = ({ item, onSelect }) => {
    const auth = useAuth();
    const user = useUser();
    const api = useApi();
    const { selectedId: accountId } = useAccount() ?? {};

    const [state, setState] = useState(typeof item === 'object' ? item : null);

    useEffect(() => {
        if (typeof item === 'object') {
            setState(item);
        } else if (item instanceof Function) {
            // function may return an object, or may return a promise, either way, wrap as promise
            setState(null);
            (async (context) => setState(await Promise.resolve(item(context))))({ auth, user, account: accountId ? { id: accountId } : null, api });
        } else if (item === null || item === undefined) {
            setState(null);
        } else {
            console.error('item is not acceptable');
        }
    }, [item, auth, user, api, accountId]);

    if (typeof state === 'object' && state) {
        return <NavListItemRender item={state} onSelect={onSelect} />
    } else {
        return <></>;
    }
};

const NavListItemRender = ({ item, onSelect }) => {
    return (
        <ListItem button sx={itemProps} disablePadding selected={useMatch(item.route) !== null} component={NavLink} to={item.route} onClick={onSelect}>
            {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
            <ListItemText inset={item.icon === null}>{item.title}</ListItemText>
        </ListItem>
    );
};