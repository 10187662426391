import { LinkedList } from './linked-list';

export class CancellationError extends Error {
    constructor(message = "", ...args) {
        super(message, ...args);
    }
}

export class CancellationToken {
    constructor() {
        this._canceled = false;
        this._onCancel = new LinkedList();
    }

    onCancel(callback) {
        if (this._canceled) {
            try {
                callback();
            } catch (err) {
                console.error(err);
            }
        } else {
            this._onCancel.append(callback);
        }
    }

    cancel() {
        if (this._canceled === false) {
            this._canceled = true;

            for (let node = this._onCancel.head; node; node = node.next) {
                try {
                    node.value();
                } catch (err) {
                    console.error(err);
                }
            }

            this._onCancel = new LinkedList();
        }
    }

    get isCancelled() {
        return this._canceled;
    }

    throwIfCancelled() {
        if (this._canceled) {
            throw new CancellationError();
        }
    }
    
}
