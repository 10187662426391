import React, { useEffect, useState } from 'react';
import urlJoin from 'proper-url-join';
import { Table, TableHead, TableBody, TableRow, TableCell, Backdrop, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, Stack, CircularProgress, Typography, Modal, Box, TextField, Grid, Divider, Alert, AlertTitle, FormControlLabel, Checkbox, FormControl, FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useLayout } from '../components/layout';
import { useAccount } from '../contexts/account';
import { useAuth } from '../contexts/auth';
import { useConfig } from '../contexts/config';
import { useApi, useApiAsync } from '../contexts/api';
import { useTasks } from '../contexts/task';
import { useUser } from '../contexts/user';
import { useApiFetch } from '../helpers/api-fetch';
import "./assets.scss";

const POST_TASK_KEY = 'assets:post';

/**
 * Checks whether the user has configuration access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountRoleAsync = async (auth, user, account, api, type) => {
    if (auth) {
        const currentAccount = account?.accounts.filter((acc) => acc.id === account.selectedId)[0];
        if (currentAccount && currentAccount.id) {
            const { data } = await api.all('userAccountRoles').get({ filter: `and(equals(account.id,'${currentAccount.id}'),equals(user.id,'${user.id}'))` });

            if (Array.isArray(type)) {
                return data.some(i => type.includes(i.type));
            } else {
                return data.some(i => i.type === type);
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
};

/**
 * Checks whether the user has admin access to the account.
 * @param {any} auth
 * @param {any} user
 * @param {any} account
 * @param {any} api
 */
const hasAccountAdminAsync = async (auth, user, account, api) => await hasAccountRoleAsync(auth, user, account, api, 'admin');

export const Assets = () => {
    const config = useConfig();
    const auth = useAuth();
    const account = useAccount();
    const user = useUser();
    const api = useApi();   
    const apiFetch = useApiFetch();
    const tasks = useTasks();

    // load asset extension fields for the account on page load
    const [assetExtensionFields, setAssetExtensionFields] = useState([]);
    const getAccountInformation = async () => {
        if(account === null){
            return [];
        }

        const response = await apiFetch(`v1/accounts/${account.selectedId}`, {
            method: 'GET',
            headers: {Accept : '*/*'}
        });

        if(response.ok === true){
            const json = await response.json();
            const assetExtensionFields = json.data.attributes.assetExtensionFields;

            setAssetExtensionFields(assetExtensionFields);
        }
    }
    useEffect(() => {
        setAssetExtensionFields(getAccountInformation());
    }, [account]);

    // general error message
    const [fail, setFail] = useState({ message: null });

    // assets to display in list
    const [assets, setAssets] = useState([]);
    const { data, isPending, reload } = useApiAsync((api, accountId) => accountId ? api.one('account', accountId).all('assets').get({ include: 'owner,tag' }) : null, [account?.selectedId]);

    // track outstanding state to post new assets
    const [post, setPost] = useState({ file: null, truncate: false });
    const [postTask, setPostTask] = useState(null);

    const [assetModalOpen, setAssetModalOpen] = useState(false);
    const handleAssetModalOpen = (id) => {
        setCurrentAsset(id);
        setAssetModalOpen(true, id);
    };
    const handleAssetModalClose = () => {
        setAssetModalOpen(false);
        setCurrentAsset(-1);
    }

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const handleOpenDeleteDialog = (id) => {
        setCurrentAsset(id);
        setDeleteDialogOpen(true, id);
    };
    const handleCloseDeleteDialog = () => {
        setCurrentAsset(-1);
        setDeleteDialogOpen(false);
    };

    const [extensionFieldModalOpen, setExtensionFieldModalOpen] = useState(false);
    const handleExtensionFieldModalOpen = () => {
        setExtensionFieldModalOpen(true);
    }
    const handleExtensionFieldModalClose = () => {
        setExtensionFieldModalOpen(false);
    }

    const [currentAsset, setCurrentAsset] = useState(-1);

    // update the page title when it is available
    const layout = useLayout();
    useEffect(() => { layout.setTitle('Assets'); return () => layout.setTitle(null); }, []);

    // receive updates to assets
    useEffect(() => {
        if (data && data.data) {
            setAssets(data.data);
        }
    }, [data, isPending]);

    const onToggleTruncate = () => {
        setPost({ ...post, truncate: !post.truncate });
    };

    const onDownloadClickAsync = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        try {
            let token = null;

            if (auth) {
                if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
                    token = auth.oidc.oidcUser.access_token;
                }
            }

            window.location.href = urlJoin(config.Api.BaseUri, `v1/accounts/${account.selectedId}/assets/bulk/download?format=csv&filename=assets.csv&access_token=${token}`);
        } catch (err) {
            setFail({ message: err.message });
        }
    };

    const onDownloadTemplateClickAsync = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        try {
            let token = null;

            if (auth) {
                if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
                    token = auth.oidc.oidcUser.access_token;
                }
            }

            window.location.href = urlJoin(config.Api.BaseUri, `v1/accounts/${account.selectedId}/assets/bulk/download?format=csv&filename=assets.csv&filter=%7B%22%24is%22%3Anull%7D&access_token=${token}`);
        } catch (err) {
            setFail({ message: err.message });
        }
    };

    const handleDelete = async() => {
        if(currentAsset !== -1){
            await apiFetch(`v1/accounts/${account.selectedId}/assets/${currentAsset}`, {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'}
            });
    
            //update the state
            setAssets(assets.filter(p => p.id !== currentAsset));
        }

        setCurrentAsset(-1);
        setDeleteDialogOpen(false);
    }

    useEffect(() => {
        setPostTask(tasks.get(POST_TASK_KEY));
    }, [tasks]);

    const onPostFileChange = (ev) => {
        setPost({ ...post, file: ev.target.files.length > 0 ? ev.target.files[0] : null });
    };

    const onPostAsync = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (postTask) {
            throw new Error('Cannot initiate a post while an ongoing post is in progress.');
        }

        if (!post.file) {
            throw new Error('No File Given');
        }

        const formData = new FormData();
        formData.append('assets', post.file);
        formData.append('truncate', post.truncate);

        // schedule post task
        const task = tasks.add(POST_TASK_KEY, async (cancellationToken) => {
            try {
                // hook up cancellation
                const abort = new AbortController();
                cancellationToken.onCancel(() => abort.abort());

                await apiFetch(`v1/accounts/${account.selectedId}/assets/bulk/upload`, {
                    method: 'POST',
                    body: formData,
                    signal: abort.signal,
                });
            } catch (err) {
                if (err.name !== 'AbortError') {
                    console.error(err);
                    return { severity: 'error', message: "An error occurred while uploading the asset file. Please try again, or see your administrator." };
                } else {
                    return null;
                }
            }

            return { severity: 'success', message: "Asset upload completed successfully." };
        });

        try {
            await task.promise;
            if (task.cancellationToken.isCancelled == false) {
                reload();
            }
        } catch (err) {
            
        }
    };

    const [hasAdmin, setHasAdmin] = useState(false);

    useEffect(async () => {
        setHasAdmin(await hasAccountAdminAsync(auth, user, account, api));
    }, [auth, user, account, api]);

    if (postTask) {
        return (
            <Backdrop sx={{ color: '#fff' }} open={true}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <CircularProgress />
                    <Typography variant="h5">Asset upload in progress...</Typography>
                </Stack>
            </Backdrop>
        );
    }

    if (isPending || !account || !account.selectedId) {
        return (
            <Backdrop sx={{ color: '#fff' }} open={true}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <CircularProgress />
                    <Typography variant="h5">Loading assets...</Typography>
                </Stack>
            </Backdrop>
        );
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={assetModalOpen}
                onClose={handleAssetModalClose} >
                <Box>
                    <AssetModal closeModal={handleAssetModalClose} allAssets={assets} currentAsset={currentAsset} 
                        setAssets={setAssets} assetExtensionFields={assetExtensionFields} />
                </Box>
            </Modal>
            <Modal 
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={extensionFieldModalOpen}
                onClose={handleExtensionFieldModalClose} >
                <Box>
                    <ExtensionFieldModal closeModal={handleExtensionFieldModalClose} extensionFields={assetExtensionFields} 
                        setExtensionFields={setAssetExtensionFields} setAssetExtensionFields={setAssetExtensionFields} 
                        getAccountInformation={getAccountInformation} />
                </Box>
            </Modal>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                {"Asset Delete"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you certain you want to delete this Asset?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDeleteDialog}><p>Nevermind</p></Button>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
            <section className="page assets-page">
                <div className="widget centered">
                    {
                        fail.message ? (
                            <div className="inner-wrapper">
                                <div className="column">
                                    <h4 className="error-message">
                                        {fail.message}
                                    </h4>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="inner-wrapper">
                        <div className="column small">
                            <form
                                className="upload-form upload-box flex-column"
                                autoComplete="off">
                                <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    accept="text/csv,application/csv"
                                    required
                                    onChange={onPostFileChange}
                                />
                                <label
                                    htmlFor="file-upload"
                                    className={`text-label button choose block ${true ? "valid" : ""}`}
                                >
                                    <span>
                                        Select CSV
                                    </span>
                                </label>
                                <div className="checkbox-container">
                                    <div className="check-holder">
                                        <input
                                            id="truncate"
                                            name="truncate"
                                            type="checkbox"
                                            value="1"
                                            onChange={onToggleTruncate}
                                            checked={post.truncate}
                                        />
                                    </div>
                                    <label htmlFor="truncate">
                                        Delete Existing?
                                    </label>
                                </div>
                                <button className="submit block" type="submit" disabled={false} onClick={onPostAsync}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="17"
                                        viewBox="0 0 20 17">
                                        <path
                                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                                        />
                                    </svg>
                                    <span>Submit</span>
                                </button>
                                <hr className="separator" />
                                <a className="button download block" onClick={onDownloadClickAsync}>
                                    <span>Download</span>
                                </a>
                                <a className="button template block" onClick={onDownloadTemplateClickAsync}>
                                    <span>Download Template</span>
                                </a>
                                {
                                    hasAdmin ? (
                                        <a className="button options block left-button" onClick={handleExtensionFieldModalOpen}>
                                            <ExtensionIcon className="left-button" />
                                            <span>Manage Extension Fields</span>
                                        </a>
                                    ) : null
                                }
                            </form>
                        </div>
                        <div className="column">
                            {
                                hasAdmin ? (
                                    <Button variant="contained" onClick={handleAssetModalOpen} sx={{ width:'100%', height:'48px', my:'5px', textTransform:'capitalize' }}>Add new asset</Button>
                                ) : null
                            }
                            <Table
                                sx={{
                                    "& .MuiTableCell-root:hover": {
                                      overflow: 'visible',
                                      wordWrap: 'break-word',
                                    }
                                  }} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Key</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Tag</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        assets.length > 0 ?
                                            assets.map(asset => (
                                                <TableRow data-id={asset.id} key={asset.id}>
                                                    <TableCell
                                                        sx={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {asset.key}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {asset.name}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {asset.tag?.key ?? "N/A"}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {asset.quantity}
                                                    </TableCell>
                                                    <TableCell sx={{ py: 'auto' }} >
                                                        {
                                                            hasAdmin ? (
                                                                <IconButton aria-label="edit" className='table-button' onClick={() => handleAssetModalOpen(asset.id)} >
                                                                    <EditIcon className="table-button" />
                                                                    <p>Edit</p>
                                                                </IconButton>
                                                            ) : null
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{ py: 'auto' }} >
                                                        {
                                                            hasAdmin ? (
                                                                <IconButton size="small" variant="contained" aria-label="delete" className='table-button' onClick={() => handleOpenDeleteDialog(asset.id)} >
                                                                    <DeleteIcon className="table-button" />
                                                                    <p>Delete</p>
                                                                </IconButton>
                                                            ) : null
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )) : (
                                                <TableRow key="no-assets">
                                                    <TableCell colSpan="2">No Assets Found</TableCell>
                                                </TableRow>
                                            )
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

const AssetModal = ({allAssets, closeModal, currentAsset, setAssets, assetExtensionFields}) => {
    const account = useAccount();    
    const apiFetch = useApiFetch();
    
    const fieldValues = Object.values(assetExtensionFields).map((myVal) => {
        return myVal.key;
    });

    const getAsset = () => {
        if(typeof currentAsset === 'string'){
            return allAssets.find(x => x.id === currentAsset);
        }
        const emptyAsset = {
            key: '',
            name: '',
            quantity: 0,
            extensionData: {}
        };

        fieldValues.map((field) => {
            emptyAsset.extensionData[field] = "";
        })

        return emptyAsset;
    }

    const [asset, setAsset] = useState(getAsset);
    const [assetId, setAssetId] = useState(asset?.id);
    const [assetKey, setAssetKey] = useState(asset?.key);
    const [assetName, setAssetName] = useState(asset?.name);
    const [assetQuantity, setAssetQuantity] = useState(asset?.quantity);
    const [assetTagKey, setAssetTagKey] = useState(asset?.tag?.key);
    const [assetActive, setAssetActive] = useState(asset?.id !== undefined ? asset.active : 1);

    const handleAssetIdChange = event => {
        setAssetId(event.target.value);
    }

    const handleAssetKeyChange = event => {
        setAssetKey(event.target.value);
    }

    const handleAssetNameChange = event => {
        setAssetName(event.target.value);
    }

    const handleAssetQuantityChange = event => {
        setAssetQuantity(parseFloat(event.target.value));
    }

    const handleAssetTagKeyChange = event => {
        setAssetTagKey(event.target.value);
    }

    const handleAssetActiveChange = event => {
        setAssetActive(event.target.checked);
    }

    const handleExtensionDataChange = event => {
        asset.extensionData[event.target.id] = event.target.value;
    }

    const assetModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        minWidth: '400px',
        maxHeight: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    };

    const handleSave = async () => {
        closeModal();

        // If asset ID is null, post new asset, else update existing asset
        if(assetId !== undefined){
            // Create the JSON body
            const asset = allAssets.find(x => x.id === currentAsset);
            asset.key = assetKey;
            asset.name = assetName;
            asset.quantity = assetQuantity;
            asset.active = assetActive;

            await apiFetch(`v1/accounts/${account.selectedId}/assets/${assetId}`, {
                method: 'PUT',
                body: JSON.stringify(asset),
                headers: {'Content-Type': 'application/json'}
            });

            //update the state
            setAssets(allAssets.map(a =>
                a.id === assetId ? { ...a, key: assetKey, name: assetName, quantity: assetQuantity, active: assetActive, extensionData: asset.extensionData } : a
            ));
        }
        else{
            // Create the new asset
            // Create the JSON body
            asset.key = assetKey;
            asset.name = assetName;
            asset.quantity = assetQuantity;
            asset.active = assetActive;
            asset.tag = {
                key: assetTagKey
            };

            const response = await apiFetch(`v1/accounts/${account.selectedId}/assets`, {
                method: 'POST',
                body: JSON.stringify(asset),
                headers: {'Content-Type': 'application/json'}
            });

            // Get the newly created Id
            const newAsset = await response.json();
            asset.id = newAsset.id;

            //update the state
            setAssets([...allAssets, asset]);
        }
    }

    const validateKey = () => {
        return assetKey.length > 0 && !allAssets.filter((x) => x.id !== assetId).some((x) => x.key === assetKey);
    }

    const validateTagKey = () => {
        if(assetTagKey === undefined || assetTagKey?.length <= 0){
            return true;
        }
        return !allAssets.filter((x) => x.id !== assetId).some((x) => x.tag?.key === assetTagKey);
    }

    const validateQuantity = () => {
        return assetQuantity >= 0 && Number.isInteger(assetQuantity) && parseInt(assetQuantity) <= 2147483647;
    }

    const[invalidInputs, setInvalidInputs] = useState(true);

    useEffect(() => {
        setInvalidInputs(!validateKey() || !validateQuantity() || !validateTagKey())
    }, [assetQuantity, assetKey, assetTagKey]);

    return (
        <React.Fragment>
            <Box sx={assetModalStyle}>
                <Box>
                    <ModalHeader asset={asset} />
                </Box>
                <DialogContent>
                    <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
                        <Stack spacing={2}>
                            <TextField
                                id="asset-key"
                                label="Unique Identifier"
                                defaultValue={asset ? asset.key : null}
                                helperText="This ID must be distinct unique among all other assets in the database"
                                error={!validateKey()}
                                onChange={handleAssetKeyChange} />
                            <TextField
                                id="asset-name"
                                label="Name"
                                defaultValue={asset ? asset.name : null}
                                helperText="The name of the asset"
                                onChange={handleAssetNameChange} />
                            <TextField
                                id="asset-quantity"
                                label="Quantity"
                                defaultValue={asset ? asset.quantity : null}
                                helperText={validateQuantity() ? "The quantity per asset" : "Quantity must be a positive integer and not exceed 2147483647"}
                                error={!validateQuantity()}
                                onChange={handleAssetQuantityChange}
                                type="number"
                                InputLabelProps={{
                                    shrink: true
                                }} />
                            <TextField
                                id="asset-tag-key"
                                label="RFID Tag ID"
                                defaultValue={asset ? asset.tag?.key : null}
                                helperText={asset?.id !== undefined ? "The RFID Tag ID cannot be updated here" : "The ID of the associated tag"}
                                error={!validateTagKey()}
                                onChange={handleAssetTagKeyChange}
                                disabled={asset?.id !== undefined} />
                            <FormControl sx={{ marginBottom: '5px' }}>
                                <FormControlLabel control={
                                        <Checkbox 
                                            onChange={handleAssetActiveChange}
                                            checked={assetActive}
                                            inputProps={{ 'aria-label': 'Asset active status' }} />
                                    } 
                                    label="Active"
                                    sx={{ fontWeight:'fontWeightMedium', textAlign:'left', color: 'black' }} />
                                <FormHelperText>
                                    Unchecking this box will remove the asset from inventory count. To remove the item from the asset list, click the delete button next to the edit button or upload a new .csv file without the item
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ fontWeight:'fontWeightMedium', textAlign:'left', color: 'black' }}>
                                    <p>Extension data</p>
                                </Box>
                            </Grid>
                            {Object.keys(assetExtensionFields).length > 0 ?
                                Object.entries(assetExtensionFields).map(([key, val]) => {
                                    return (
                                        <Grid item xs={12} md={6} lg={6} xl={4} key={key} >
                                            <TextField 
                                                id={val.key} 
                                                label={val.key} 
                                                defaultValue={asset?.extensionData[val.key]}
                                                onChange={handleExtensionDataChange} />
                                        </Grid>
                                    );
                                }) : null
                            }
                        </Grid>
                    </Stack>
                </DialogContent>
                <Stack spacing={2} direction="row" justifyContent="flex-end" >
                    <Button variant="contained" disabled={invalidInputs} color="success" startIcon={<SaveIcon />} onClick={handleSave}>Save</Button>
                    <Button variant="contained" color="error" startIcon={<CancelIcon />} onClick={closeModal}>Cancel</Button>
                </Stack>
            </Box>
        </React.Fragment>
    );
}

const ModalHeader = ({asset}) => {
    if(asset.key.length !== 0){
        return (
            <Box sx={{ textAlign:'center', color: 'black' }}>
                <h1>Edit Asset</h1>
            </Box>
        );
    }
    
    return (
        <Box sx={{ textAlign:'center', color: 'black' }}>
            <h1>New Asset</h1>
        </Box>
    );
}

const ExtensionFieldModal = ({ closeModal, extensionFields, setExtensionFields, setAssetExtensionFields, getAccountInformation }) => {
    const account = useAccount();
    const apiFetch = useApiFetch();

    const extensionFieldModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        minWidth: '400px',
        maxHeight: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    };

    const [deletedExtensionFields, setDeleteExtensionFields] = useState([]);
    
    const [extensionDataModalOpen, setExtensionDataModalOpen] = useState(false);
    const [currentExtensionField, setCurrentExtensionField] = useState();

    const handleOpenExtensionDataModal = () => {
        setCurrentExtensionField();
        setExtensionDataModalOpen(true);
    }
    const handleExtensionDataModalClose = () => {
        setExtensionDataModalOpen(false);
    }

    const handleEditExtensionField = (key) => {
        setCurrentExtensionField(key);
        setExtensionDataModalOpen(true);
    }

    const handleDeleteExtensionField = (key) => {
        const newExtensionFields = {...extensionFields};
        delete newExtensionFields[key];
        setDeleteExtensionFields([...deletedExtensionFields, key]);

        //update the state
        setExtensionFields(newExtensionFields);
    }

    const handleSave = async () => {
        let promises = [];

        Object.entries(extensionFields).map(([key, val]) => {
            promises.push(
                apiFetch(`v1/accounts/${account.selectedId}/extensionField/${key}`, {
                    method: 'PUT',
                    body: JSON.stringify(val),
                    headers: {'Content-Type': 'application/json'}
                })
            );
        });

        for (let i in deletedExtensionFields){
            promises.push(
                apiFetch(`v1/accounts/${account.selectedId}/extensionField/${deletedExtensionFields[i]}`, {
                    method: 'DELETE',
                    headers: {'Content-Type': 'application/json'}
                })
            );
        };

        closeModal();

        await Promise.all(promises);

        setAssetExtensionFields(getAccountInformation());
    }

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={extensionDataModalOpen}
                onClose={handleExtensionDataModalClose} >
                <Box>
                    <NewExtensionFieldModal closeModal={handleExtensionDataModalClose} currentExtensionField={currentExtensionField} 
                        extensionFields={extensionFields} setExtensionFields={setExtensionFields} />
                </Box>
            </Modal>
            <Box sx={extensionFieldModalStyle}>
                <Box sx={{ textAlign:'center', color: 'black' }}>
                    <h1>Manage Asset Extension Data</h1>
                </Box>
                <Alert severity="warning" variant="outlined">
                    <AlertTitle>Changing Asset Extension Field information?</AlertTitle>
                    Updating data here carries <strong>high risk of breaking existing action logic!</strong>
                    <br />
                    Updating the extension path will no longer allow editing the asset data associated with the previous path.
                    <br />
                    Same warning for deleting Asset Extension Fields.
                </Alert>
                <DialogContent>
                    <Grid container spacing={2}>
                        {Object.entries(extensionFields).map(([key, val]) => {
                            return (
                                <Grid item xs={12} md={6} lg={6} xl={4} key={key} >
                                    <TextField 
                                        id={key} 
                                        label={key} 
                                        defaultValue={val.key}
                                        disabled />
                                    <IconButton 
                                        aria-label="edit" 
                                        sx={{ display: 'inline', marginTop: '7px' }}
                                        onClick={() => handleEditExtensionField(key)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton 
                                        aria-label="delete" 
                                        sx={{ display: 'inline', marginTop: '7px' }}
                                        onClick={() => handleDeleteExtensionField(key)}>
                                        <DeleteIcon />
                                    </IconButton> 
                                </Grid>
                            );
                        })}
                        <Grid item xs={4}>
                            <TextField 
                                id="new-asset-extension-data"
                                label="New Extension Data"
                                defaultValue="New Extension Data"
                                disabled />
                            <IconButton 
                                aria-label="add" 
                                sx={{ display: 'inline', marginTop: '7px' }}
                                onClick={handleOpenExtensionDataModal}>
                                <AddCircleIcon color="success" />
                            </IconButton> 
                        </Grid>
                    </Grid>
                </DialogContent>
                <Stack spacing={2} direction="row" justifyContent="flex-end" >
                    <Button variant="contained" color="success" startIcon={<SaveIcon />} onClick={handleSave}>Save</Button>
                    <Button variant="contained" color="error" startIcon={<CancelIcon />} onClick={closeModal}>Cancel</Button>
                </Stack>
            </Box>
        </React.Fragment>
    )
}

const NewExtensionFieldModal = ({closeModal, currentExtensionField, extensionFields, setExtensionFields}) => {
    const [extensionFieldKey, setNewExtensionFieldKey] = useState(currentExtensionField ? currentExtensionField : "");
    const [extensionFieldName, setNewExtensionFieldName] = useState(currentExtensionField ? extensionFields[currentExtensionField].name : "");
    const [extensionFieldExtensionKey, setNewExtensionFieldExtensionKey] = useState(currentExtensionField ? extensionFields[currentExtensionField].key : "");

    const handleExtensionFieldKeyChange = event => {
        setNewExtensionFieldKey(event.target.value);   
    }

    const handleExtensionFieldNameChange = event => {
        setNewExtensionFieldName(event.target.value);   
    }

    const handleExtensionFieldExtensionKeyChange = event => {
        setNewExtensionFieldExtensionKey(event.target.value);   
    }

    const handleSave = () => {
        const newExtensionFields = {...extensionFields}
        newExtensionFields[extensionFieldKey] = {
            name: extensionFieldName,
            key: extensionFieldExtensionKey
        };

        setExtensionFields(newExtensionFields);

        closeModal();
    }

    const isKeyInputInvalid = () => {
        // regex returns true if first letter is capitalized, or if the value contains a non-alphanumeric character
        const regex = /^[A-Z].*|[^a-zA-Z\d:]/;
        return extensionFieldKey.length == 0 || regex.test(extensionFieldKey);
    }

    const isNameInputInvalid = () => {
        return extensionFieldName.length == 0;
    }

    const isExtensionKeyInputInvalid = () => {
        return extensionFieldExtensionKey.length == 0;
    }

    const extensionDataModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxHeight: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
    };

    return (
        <Box sx={extensionDataModalStyle}>
            <Box>
                <Box sx={{ textAlign:'center', color: 'black' }}>
                    <h1>New Asset Extension Data</h1>
                </Box>
            </Box>
            <Stack spacing={2}>
                <TextField
                    id="extension-data-key"
                    label="Extension Data Key"
                    helperText="The key for the asset extension field"
                    error={isKeyInputInvalid()}
                    onChange={handleExtensionFieldKeyChange}
                    disabled={currentExtensionField !== undefined}
                    defaultValue={extensionFieldKey} />
                <TextField
                    id="extension-data-name"
                    label="Extension Data Name"
                    helperText="The name for the asset extension field"
                    error={isNameInputInvalid()}
                    onChange={handleExtensionFieldNameChange}
                    defaultValue={extensionFieldName} />
                <TextField
                    id="extension-field-extension-key"
                    label="Extension Field Extension Key"
                    helperText="The extension key for the asset extension field"
                    error={isExtensionKeyInputInvalid()}
                    onChange={handleExtensionFieldExtensionKeyChange}
                    defaultValue={extensionFieldExtensionKey} />
            </Stack>
            
            <Stack spacing={2} direction="row" justifyContent="flex-end" >
                <Button 
                    variant="contained" 
                    color="success" 
                    disabled={isKeyInputInvalid() || isNameInputInvalid() || isExtensionKeyInputInvalid()}
                    startIcon={<SaveIcon />} 
                    onClick={handleSave} >
                    Save
                </Button>
                    <Button variant="contained" color="error" startIcon={<CancelIcon />} onClick={closeModal}>Cancel</Button>
            </Stack>
        </Box>
    );
}