import React from "react";
import { useAccount } from "../contexts/account";
import { useApiAsync } from "../contexts/api";
import { useAuth } from "../contexts/auth";

const AccountMenuReady = () => {
    const auth = useAuth();
    const account = useAccount();
    const { data, isPending } = useApiAsync(api => auth ? api.all('accounts').get() : api.all('accounts').get({ filter: `equals(id,'00000000-0000-0000-0000-000000000001')` }));

    if (isPending || !account) {
        return <></>;
    }

    return (
        <select value={account ? account.selectedId : null} onChange={(ev) => account.setSelectedId(ev.target.value)}>
            {
                data && data.data ? data.data.map(i =>
                    <option key={i.id} value={i.id}>{i.name}</option>
                ) : null
            }
        </select>
    );
}

export const AccountMenu = () => {
    const auth = useAuth();

    if (!auth || auth.ready) {
        return <AccountMenuReady />;
    } else {
        return <></>
    }
};
