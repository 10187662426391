/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react"
import React, { useEffect, useState } from "react";
import { useHref } from "react-router-dom";
import { useSelectionsContext } from "../contexts/selection";
import { RegionTagPopup } from './region-tag-popup';
import { ReactComponent as DefaultAvatarSvg } from '../assets/images/circle.svg';
import { defaultColors, evalColorExpressionAsync } from '../helpers/colors';
import { evalExpressionAsync } from '../helpers/expressions';
import { useAuth } from "../contexts/auth";
import { useApi } from "../contexts/api";

const Color = require('color');

/**
 * Gets the color for the given zone.
 */
const getZoneColor = (zone) => {
    return zone && zone.color ? defaultColors[zone.color] : null;
};

/**
 * Watches the monitor and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const evalTagColorAsync = async (region, tag) => {
    return {
        primary: region.tagPrimaryColorExpressionType ? await evalColorExpressionAsync(region.tagPrimaryColorExpressionType, region.tagPrimaryColorExpression, tag) : getZoneColor(tag.zone),
        secondary: region.tagSecondaryColorExpressionType ? await evalColorExpressionAsync(region.tagSecondaryColorExpressionType, region.tagSecondaryColorExpression, tag) : null,
    };
}

/**
 * Watches the monitor and returns the computed color value.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const useTagColor = (region, tag) => {
    const [state, setState] = useState({ primary: null, secondary: null });

    useEffect(() => {
        (async (region, tag) => {
            try {
                setState(await evalTagColorAsync(region, tag));
            } catch (e) {
                console.log(e);
            }
        })(region, tag);
    }, [region, tag])

    return state;
}

/**
 * Executes a image expression asynchronously.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
export const evalImageKeyExpressionAsync = async (type, expression, data) => {
    return evalExpressionAsync(type, expression, data);
}

/**
 * Watches the tag and returns the computed image key.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const evalTagImageKeyAsync = async (region, tag) => {
    return await region.tagImageKeyExpressionType ? evalImageKeyExpressionAsync(region.tagImageKeyExpressionType, region.tagImageKeyExpression, tag) : '';
}

/**
 * Watches the tag and returns the computed image key.
 * @param {any} type
 * @param {any} expression
 * @param {any} data
 */
const useTagImageKey = (region, tag) => {
    const [state, setState] = useState(null);

    useEffect(() => {
        (async (region, tag) => {
            try {
                setState(await evalTagImageKeyAsync(region, tag));
            } catch (e) {
                console.log(e);
            }
        })(region, tag);
    }, [region, tag])

    return state;
}

/**
 * Gets the image with the given key.
 * @param {any} accountId
 * @param {any} api
 * @param {any} key
 * @param {any} color
 * @returns
 */
const getImageByKeyAsync = async (accountId, api, key, color, baseurl, token) => {
    if (accountId) {
        // Get the image ID
        const { data } = await api.all('image').get({ filter: `and(equals(account.id,'${accountId}'),equals(key,'${key}'))` });
        if (!data || data.length <= 0) {
            return null;
        }

        const imageUrl = `${baseurl}v1/tagImage/${data[0].id}/content`;

        const searchParams = new URLSearchParams();
        try {
            const primaryColor = Color(color.primary);
            const secondaryColor = Color(color.secondary);
            searchParams.append("primaryStroke", primaryColor.hexa());
            searchParams.append("primaryFill", primaryColor.hexa());
            searchParams.append("secondaryStroke", secondaryColor.hexa());
            searchParams.append("secondaryFill", secondaryColor.hexa());
            if (token) {
                searchParams.append('access_token', token);
            }
        }
        catch (error) {
            // Could not parse the colors submitted, so no custom colors will be used
            console.log("Invalid color: " + error);
        }

        return imageUrl + "?" + searchParams.toString();
    }

    return null;
};

/**
 * Watches the tag key and returns the computed image URL.
 * @param {any} region
 * @param {any} key
 * @param {any} color
 */
const useTagImageUrl = (region, key, color, baseurl) => {
    const [state, setState] = useState(null);
    const auth = useAuth();
    const api = useApi();

    let token = null;
    if (auth) {
        if (auth.oidc && auth.oidc.oidcUser && auth.oidc.oidcUser.access_token) {
            token = auth.oidc.oidcUser.access_token;
        }
    }

    useEffect(() => {
        (async (key) => {
            if (key && (color.primary || color.secondary)) {
                const image = await getImageByKeyAsync(region.account.id, api, key, color, baseurl, token);
                if (image == null) {
                    setState(null);
                    return;
                }
                setState(image.toString());
            } else {
                setState(null);
            }
        })(key);
    }, [key, color.primary, color.secondary])

    return state;
};

/**
 * Renders the Box SVG with an applied color.
 * @param {any} region
 * @param {any} tag
 */
const RegionTagAvatar = ({ region, tag, color }) => {
    const baseurl = useHref('/');
    const imageUrl = useTagImageUrl(region, useTagImageKey(region, tag), color, baseurl);
    if (imageUrl) {
        return (
            <img
                src={imageUrl}
                css={css({
                    '& .primary-fill': {
                        fill: color?.primary
                    },
                    '& .primary-stroke': {
                        stroke: color?.primary
                    },
                    '& .secondary-fill': {
                        fill: color?.secondary
                    },
                    '& .secondary-stroke': {
                        stroke: color?.secondary
                    }
                })}
                className="avatar"
                width="100%"
                height="100%" />
        );
    } else {
        return (
            <DefaultAvatarSvg
                css={css({
                    '& .primary-fill': {
                        fill: color?.primary
                    },
                    '& .primary-stroke': {
                        stroke: color?.primary
                    },
                    '& .secondary-fill': {
                        fill: color?.secondary
                    },
                    '& .secondary-stroke': {
                        stroke: color?.secondary
                    }
                })}
                className="avatar"
                width="100%"
                height="100%" />
        );
    }
};

export const TagDot = ({ region, tag, offset, scale, size }) => {
    const { selection, setSelection } = useSelectionsContext();

    const className = (selection?.type === 'tag' && selection?.item === tag.id) ? "selected-tag" : "";
    const color = useTagColor(region, tag);
    const label = tag.asset && tag.asset.owner && tag.asset.owner.name ? `${tag.asset.owner.name?.split(" ").map(v => v[0]).join("")}` : null;

    // begin style property
    const style = {
        left: `${100 * ((tag.x + offset.x) / scale.width)}%`,
        bottom: `${100 * ((tag.y + offset.y) / scale.height)}%`,
    };

    // size was specified
    if (size) {
        const sz = Number(size);
        style.width = `${sz}%`;
        style.paddingBottom = `${sz}%`;
    }

    return (
        <div key={tag.id} className={`tag-dot ${className}`} style={style} data-id={tag.id} data-x={tag.x} data-y={tag.y} onClick={ev => setSelection({ type: 'tag', item: tag.id })}>
            <div className="content">
                <div className="text">
                    <svg viewBox="0 0 75 20">
                        <text
                            x="50%"
                            y="50%"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fill="#FFF"
                            fontWeight="bolder">
                            {label}
                        </text>
                    </svg>
                </div>
                <div style={{ width: '100%' }} className="avatar-container">
                    <RegionTagAvatar region={region} tag={tag} color={color} />
                </div>
            </div>
            {selection?.type === 'tag' && selection?.item === tag.id ? <RegionTagPopup region={region} tag={tag} color={color} className="popup" /> : null}
        </div >
    );
};
