import React, { useMemo } from "react";
import { TagZone } from "./tag-zone";

export const TagZones = ({ region, scale }) => {
    const offset = useMemo(() => {
        if (region && region.zones) {
            return {
                x: -(Math.min(Math.min(...region.zones.map(i => i.corner1X)), Math.min(...region.zones.map(i => i.corner2X)))),
                y: -(Math.min(Math.min(...region.zones.map(i => i.corner1Y)), Math.min(...region.zones.map(i => i.corner2Y)))),
            };
        } else {
            return null;
        }
    }, [region.id]);

    if (region && region.zones) {
        return <>
            {
                region.zones
                    .map(zone =>
                        <TagZone
                            key={zone.id}
                            zone={zone}
                            offset={offset}
                            scale={scale}
                            labels={zone.groups?.map(g => g.name)}
                            {...zone}
                        />
                    )
            }
        </>;
    } else {
        return null;
    }
};
